console.log(`Project running in ${process.env.NODE_ENV} mode`)

const appConfig = {
  env: process.env.NODE_ENV,
  apiUrl: process.env.REACT_APP_API_URL ?? 'https://erp-backend.vennyx.com',
  // apiUrl: 'https://localhost:7143',
  appVersion: '1.0.7',
}

export default appConfig
