import { createContext, useContext } from 'react'
import { DrawerContextProps } from './drawer.context.types'

export const DrawerContext = createContext<DrawerContextProps | undefined>(undefined)

export const useDrawer = () => {
  const context = useContext(DrawerContext)
  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerProvider')
  }
  return context
}
