import React from 'react'
import { LabelProps } from '@rjsf/core/lib/components/templates/FieldTemplate/Label'
import { FormLabel, Text } from '@chakra-ui/react'

const WidgetFormLabel = ({ label, required, id }: LabelProps) => {
  if (!label) {
    return null
  }

  return (
    <FormLabel htmlFor={id}>
      {label}
      {required && (
        <Text ml={1} as="span" color="red.300" role="presentation">
          *
        </Text>
      )}
    </FormLabel>
  )
}

export default WidgetFormLabel
