import React, { ReactNode, useEffect, useMemo } from 'react'
import { useDisclosure, useMediaQuery } from '@chakra-ui/react'
import { DrawerContextProps } from './drawer.context.types'
import { DrawerContext } from './drawer.context'

const DrawerProvider = ({ children }: { children: ReactNode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false })
  const [isMobile] = useMediaQuery('(max-width: 767px)')

  useEffect(() => {
    if (isMobile) {
      onClose()
    } else {
      onOpen()
    }
  }, [isMobile, onClose, onOpen])

  const value: DrawerContextProps = useMemo(
    () => ({
      isOpen,
      onOpen,
      onClose,
    }),
    [isOpen, onOpen, onClose]
  )

  return <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
}

export default DrawerProvider
