import { Button, HStack, Select, Stack, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { RepeatIcon } from '@chakra-ui/icons'
import { ActionButtonsProps } from './data-table.component.types'
import { useTranslation } from 'react-i18next'
import { FaPlus, FaRegFileExcel, FaRegFilePdf } from 'react-icons/fa'

const ActionButtons: FC<ActionButtonsProps> = ({ onRefresh, isRefreshing, onCreateClick, onPageSizeChange, pageSize, onExportPdf, onExportExcel }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'dataTable' })

  const handlePageSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onPageSizeChange?.(parseInt(e.target.value))
  }

  return (
    <Stack justifyContent="space-between" w="100%" direction={{ base: 'column', md: 'row' }}>
      <HStack justifyContent={{ base: 'flex-end', md: 'flex-start' }}>
        <Text fontSize="sm" color="gray.500">
          {t('perPage')}
        </Text>
        <Select w="4.5rem" size="sm" value={pageSize} onChange={handlePageSizeChange}>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
        </Select>
      </HStack>
      <HStack justifyContent="flex-end">
        <Button size="sm" leftIcon={<FaRegFileExcel />} colorScheme="blue" variant="outline" onClick={onExportExcel}>
          {t('exportExcel')}
        </Button>
        <Button size="sm" leftIcon={<FaRegFilePdf />} colorScheme="teal" variant="outline" onClick={onExportPdf}>
          {t('exportPdf')}
        </Button>
        <Button size="sm" leftIcon={<FaPlus />} colorScheme="green" variant="outline" onClick={onCreateClick}>
          {t('new')}
        </Button>
        <Button size="sm" colorScheme="blue" variant="outline" leftIcon={<RepeatIcon />} onClick={onRefresh} isLoading={isRefreshing}>
          {t('refresh')}
        </Button>
      </HStack>
    </Stack>
  )
}

export default ActionButtons
