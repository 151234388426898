import { ColorHues, extendTheme, StyleConfig, type ThemeConfig } from '@chakra-ui/react'
import { GlobalStyles } from '@chakra-ui/theme-tools'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
  cssVarPrefix: 'vennyx',
}

export const colors: Record<string, ColorHues> = {}

const styles: GlobalStyles = {}

const fonts = {}

const components: Record<string, StyleConfig> = {}

const theme = extendTheme({ config, fonts, styles, colors, components })

export default theme
