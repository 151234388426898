import { useTranslation } from 'react-i18next'
import { Box, Center, Heading, Spinner, useToast } from '@chakra-ui/react'
import { Form, UiSchema } from '@rjsf/chakra-ui'
import schema from '../../utils/form-schema.util'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import validator from '@rjsf/validator-ajv8'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ProductCategoryApi } from '../../utils/api-service.util'
import { useParams } from 'react-router-dom'
import fixupFormStrings, { fixupFormErrorStrings } from '../../utils/fixup-form-strings.util'

const ProductCategoriesEditPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'productCategories' })
  const toast = useToast()
  const [formData, setFormData] = React.useState<object | undefined>()
  const { id } = useParams()

  const { data, isFetching } = useQuery({
    queryKey: ['productCategory', id],
    queryFn: () => ProductCategoryApi.apiProductCategoryGet(id ?? ''),
    enabled: !!id,
  })

  useEffect(() => {
    if (data?.data) {
      setFormData({ schema: data.data })
    }
  }, [data?.data])

  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      submitText: t('update'),
    },
    schema: {
      id: {
        'ui:title': t('id'),
        'ui:readonly': true,
      },
      name: {
        'ui:title': t('name'),
      },
    },
  }

  const updateMutation = useMutation({
    mutationFn: ProductCategoryApi.apiProductCategoryPatch,
    onSuccess: () => {
      toast({
        title: t('updateSuccess'),
        status: 'success',
      })
    },
    onError: () => {
      toast({
        title: t('updateFailed'),
        status: 'error',
      })
    },
  })

  if (!formData || isFetching) {
    return (
      <Center height="calc(100dvh - 10rem)">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box width="100%" display="flex" mt={15} flexDirection="column" alignItems="center">
      <Heading as="h1" size="xl" textAlign="center" mb={5}>
        {t('edit')}
      </Heading>
      <Box width="full" maxWidth="lg">
        <Form
          formData={formData}
          schema={schema('', FormSchemaTypes.UpdateProductCategoryCommand)}
          onChange={data => setFormData(data.formData)}
          onSubmit={data => updateMutation.mutate(data.formData.schema)}
          validator={validator}
          uiSchema={uiSchema}
          showErrorList={false}
          noHtml5Validate
          focusOnFirstError
          translateString={fixupFormStrings}
          transformErrors={fixupFormErrorStrings}
        />
      </Box>
    </Box>
  )
}

export default ProductCategoriesEditPage
