import React from 'react'
import { Box, Button, Flex, HStack, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../contexes/auth/auth.context'
import { FaUserCircle } from 'react-icons/fa'
import { useDrawer } from '../../contexes/drawer/drawer.context'
import { MdOutlineMenu } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Routes from '../../enums/routes.enum'
import { FiMoon, FiSun } from 'react-icons/fi'
import { LuLogOut } from 'react-icons/lu'
import { PiKey } from 'react-icons/pi'

const Navbar: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'navbar' })
  const auth = useAuth()
  const drawer = useDrawer()
  const navigate = useNavigate()
  const { colorMode, toggleColorMode } = useColorMode()

  const handleLogout = () => {
    auth.logout()
    navigate(Routes.LOGIN)
  }

  const handleChangePassword = () => {
    navigate(Routes.CHANGE_PASSWORD)
  }

  return (
    <Box width="100%" borderRadius="lg" paddingX={4} boxShadow="md" bg={useColorModeValue('gray.50', 'gray.700')}>
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <HStack alignItems="center">
          <IconButton aria-label="menu" icon={<MdOutlineMenu size={24} />} onClick={drawer.onOpen} display={{ base: 'flex', md: 'none' }} />
        </HStack>
        <HStack alignItems="center">
          <IconButton
            aria-label="toggle color mode"
            borderRadius="full"
            icon={colorMode === 'light' ? <FiMoon size={24} /> : <FiSun size={24} />}
            onClick={toggleColorMode}
            colorScheme="gray"
            variant="solid"
          />

          <Menu placement="bottom-end">
            <MenuButton as={Button} rounded="full" variant="link" minW={0} _hover={{ opacity: 0.8 }}>
              <FaUserCircle size={40} />
            </MenuButton>
            <MenuList>
              <Text fontSize="sm" color="gray.500" mx={2}>
                {auth.decodedToken?.email}
              </Text>
              <MenuDivider />
              <MenuItem icon={<PiKey size={20} />} onClick={handleChangePassword}>
                {t('changePassword')}
              </MenuItem>
              <MenuDivider />
              <MenuItem color="red.300" icon={<LuLogOut size={20} />} onClick={handleLogout}>
                {t('logout')}
              </MenuItem>
            </MenuList>
          </Menu>
        </HStack>
      </Flex>
    </Box>
  )
}

export default Navbar
