import React, { KeyboardEvent, useRef } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { useMutation } from '@tanstack/react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useAuth } from '../../contexes/auth/auth.context'
import { AuthStatus } from '../../contexes/auth/auth.context.types'
import Routes from '../../enums/routes.enum'
import { MyAccountApi } from '../../utils/api-service.util'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import VennyxLogoIcon from '../../components/icons/vennyx-logo.icon'

const LoginPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'login' })
  const toast = useToast()
  const auth = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { isOpen, onToggle } = useDisclosure()
  const emailInputRef = useRef<HTMLInputElement>(null)
  const passwordInputRef = useRef<HTMLInputElement>(null)

  const validationSchema = Yup.object({
    email: Yup.string().email(t('emailInvalid')).required(t('emailRequired')),
    password: Yup.string().min(6, t('passwordInvalid')).required(t('passwordRequired')),
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: values => {
      loginMutation.mutate(values)
    },
  })

  const loginMutation = useMutation({
    mutationFn: MyAccountApi.apiMyAccountLoginPost,
    mutationKey: [FormSchemaTypes.LoginCommand],
    onSuccess: ({ data }) => {
      auth.updateAuthStatus(JSON.stringify(data))

      toast({
        title: t('loginSuccessTitle'),
        description: t('loginSuccessDescription'),
        status: 'success',
      })

      const from = location.state?.from?.pathname || Routes.HOME
      navigate(from, { replace: true })
    },
    onError: () => {
      auth.changeLoadingStatus(AuthStatus.UNAUTHENTICATED)
      toast({
        title: t('loginFailedTitle'),
        description: t('loginFailedDescription'),
        status: 'error',
      })
    },
  })

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!formik.values.email) {
        emailInputRef.current?.focus()
        return
      }

      if (!formik.values.password) {
        passwordInputRef.current?.focus()
        return
      }
    }
  }

  const boxBg = useColorModeValue('gray.50', 'gray.700')

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '4', md: '8' }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing="8">
          <Center>
            <VennyxLogoIcon width="12rem" />
          </Center>
          <Heading textAlign="center">{t('title')}</Heading>
          <Box bg={boxBg} boxShadow="md" p="8" borderRadius="xl">
            <Stack spacing="6">
              <FormControl isInvalid={Boolean(formik.errors.email && formik.touched.email)}>
                <FormLabel htmlFor="email">{t('emailLabel')}</FormLabel>
                <Input id="email" type="email" {...formik.getFieldProps('email')} ref={emailInputRef} onKeyDown={handleKeyDown} autoComplete="email" />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(formik.errors.password && formik.touched.password)}>
                <FormLabel htmlFor="password">{t('passwordLabel')}</FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    type={isOpen ? 'text' : 'password'}
                    {...formik.getFieldProps('password')}
                    ref={passwordInputRef}
                    onKeyDown={handleKeyDown}
                    autoComplete="current-password"
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                      variant="ghost"
                      colorScheme="blue"
                      borderLeftRadius={0}
                      icon={isOpen ? <HiEyeOff /> : <HiEye />}
                      onClick={onToggle}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>
              <HStack justify="flex-end">
                <Button variant="link" size="sm" onClick={() => navigate(Routes.FORGET_PASSWORD)}>
                  {t('forgotPassword')}
                </Button>
              </HStack>
              <Button isLoading={loginMutation.isPending} loadingText={t('signing')} colorScheme="blue" type="submit" isDisabled={!formik.isValid || !formik.dirty}>
                {t('signInButton')}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Container>
  )
}

export default LoginPage
