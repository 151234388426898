import React from 'react'
import { createRoot } from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import reportWebVitals from './reportWebVitals'
import themeConfig from './configs/theme.config'
import RoutesEnum from './enums/routes.enum'
import Layout from './components/layout/layout.component'
import ProtectedRoute from './components/protected-route/protected-route.component'
import AuthenticatedRedirect from './components/authenticated-redirect/authenticated-redirect.component'
import HomePage from './routes/home/home.page'
import LoginPage from './routes/login/login.page'
import ForgetPasswordPage from './routes/forget-password/forget-password.page'
import TradingPartnersCreatePage from './routes/trading-partners/trading-partners-create.page'
import TradingPartnersPage from './routes/trading-partners/trading-partners.page'
import ProductsPage from './routes/products/products.page'
import ProductsCreatePage from './routes/products/product-create.page'
import TradingPartnersEditPage from './routes/trading-partners/trading-partners-edit.page'
import AccountsPage from './routes/accounts/accounts.page'
import AccountsCreatePage from './routes/accounts/accounts-create.page'
import AccountsEditPage from './routes/accounts/accounts-edit.page'
import AuthProvider from './contexes/auth/auth.provider.context'
import TokenRefresherProvider from './contexes/token-refresher/token-refresher.provider.context'
import DrawerProvider from './contexes/drawer/drawer.provider.context'
import ProductCategoriesPage from './routes/product-categories/product-categories.page'
import ProductCategoriesCreatePage from './routes/product-categories/product-categories-create.page'
import ProductCategoriesEditPage from './routes/product-categories/product-categories-edit.page'
import RestrictedPage from './routes/restricted/restricted.page'
import UserRoles from './enums/user-roles.enum'
import ProductEditPage from './routes/products/product-edit.page'
import CitiesPage from './routes/cities/cities.page'
import CitiesEditPage from './routes/cities/cities-edit.page'
import CitiesCreatePage from './routes/cities/cities-create.page'
import OffersPage from './routes/offers/offers.page'
import OffersCreatePage from './routes/offers/offers-create.page'
import OffersEditPage from './routes/offers/offers-edit.page'
import OfferCategoryPage from './routes/offer-category/offer-category.page'
import OfferCategoryCreatePage from './routes/offer-category/offer-category-create.page'
import OfferCategoryEditPage from './routes/offer-category/offer-category-edit.page'
import DistrictsPage from './routes/districts/districts.page'
import DistrictsCreatePage from './routes/districts/districts-create.page'
import DistrictsEditPage from './routes/districts/districts-edit.page'
import CurrenciesPage from './routes/currencies/currencies.page'
import CurrenciesEditPage from './routes/currencies/currencies-edit.page'
import CurrenciesCreatePage from './routes/currencies/currencies-create.page'
import ProductUnitPage from './routes/product-unit/product-unit.page'
import ProductUnitCreatePage from './routes/product-unit/product-unit-create.page'
import ProductUnitEditPage from './routes/product-unit/product-unit-edit.page'
import ChangePasswordPage from './routes/change-password/change-password.page'

import './styles/main.scss'
import './i18n'
import 'moment-timezone'
import OrdersPage from './routes/orders/orders.page'
import OrderCreatePage from './routes/orders/order-create.page'
import OrderEditPage from './routes/orders/order-edit.page'
import OrderCategoryPage from './routes/order-category/order-category.page'
import OrderCategoryCreatePage from './routes/order-category/order-category-create.page'
import OrderCategoryEditPage from './routes/order-category/order-category-edit.page'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: 1 },
    mutations: { retry: 1 },
  },
})

const AppWrapper = () => {
  return (
    <ChakraProvider theme={themeConfig} toastOptions={{ defaultOptions: { duration: 3000, position: 'top', isClosable: true } }}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AuthProvider>
          <TokenRefresherProvider>
            <DrawerProvider>
              <BrowserRouter>
                <Routes>
                  <Route element={<AuthenticatedRedirect />}>
                    <Route path={RoutesEnum.LOGIN} element={<LoginPage />} />
                    <Route path={RoutesEnum.FORGET_PASSWORD} element={<ForgetPasswordPage />} />
                  </Route>
                  <Route element={<ProtectedRoute children={<Layout />} />}>
                    <Route path={RoutesEnum.HOME} element={<HomePage />} />
                    <Route path={RoutesEnum.RESTRICTED} element={<RestrictedPage />} />
                    <Route path={RoutesEnum.TRADING_PARTNERS} element={<TradingPartnersPage />} />
                    <Route path={RoutesEnum.TRADING_PARTNERS_CREATE} element={<TradingPartnersCreatePage />} />
                    <Route path={RoutesEnum.TRADING_PARTNERS_EDIT} element={<TradingPartnersEditPage />} />
                    <Route path={RoutesEnum.PRODUCTS} element={<ProductsPage />} />
                    <Route path={RoutesEnum.PRODUCTS_CREATE} element={<ProductsCreatePage />} />
                    <Route path={RoutesEnum.PRODUCTS_EDIT} element={<ProductEditPage />} />
                    <Route path={RoutesEnum.PRODUCT_CATEGORIES} element={<ProductCategoriesPage />} />
                    <Route path={RoutesEnum.PRODUCT_CATEGORY_CREATE} element={<ProductCategoriesCreatePage />} />
                    <Route path={RoutesEnum.PRODUCT_CATEGORY_EDIT} element={<ProductCategoriesEditPage />} />
                    <Route path={RoutesEnum.OFFERS} element={<OffersPage />} />
                    <Route path={RoutesEnum.OFFERS_CREATE} element={<OffersCreatePage />} />
                    <Route path={RoutesEnum.OFFERS_EDIT} element={<OffersEditPage />} />
                    <Route path={RoutesEnum.OFFER_CATEGORIES} element={<OfferCategoryPage />} />
                    <Route path={RoutesEnum.OFFER_CATEGORY_CREATE} element={<OfferCategoryCreatePage />} />
                    <Route path={RoutesEnum.OFFER_CATEGORY_EDIT} element={<OfferCategoryEditPage />} />
                    <Route path={RoutesEnum.CHANGE_PASSWORD} element={<ChangePasswordPage />} />
                    <Route path={RoutesEnum.ORDERS} element={<OrdersPage />} />
                    <Route path={RoutesEnum.ORDERS_CREATE} element={<OrderCreatePage />} />
                    <Route path={RoutesEnum.ORDERS_EDIT} element={<OrderEditPage />} />
                    <Route path={RoutesEnum.ORDER_CATEGORY} element={<OrderCategoryPage />} />
                    <Route path={RoutesEnum.ORDER_CATEGORY_CREATE} element={<OrderCategoryCreatePage />} />
                    <Route path={RoutesEnum.ORDER_CATEGORY_EDIT} element={<OrderCategoryEditPage />} />
                  </Route>
                  <Route element={<ProtectedRoute allowedRoles={[UserRoles.OWNER, UserRoles.MANAGER]} children={<Layout />} />}>
                    <Route path={RoutesEnum.ACCOUNTS} element={<AccountsPage />} />
                    <Route path={RoutesEnum.ACCOUNTS_CREATE} element={<AccountsCreatePage />} />
                    <Route path={RoutesEnum.ACCOUNTS_EDIT} element={<AccountsEditPage />} />
                  </Route>
                  <Route element={<ProtectedRoute allowedRoles={[UserRoles.OWNER]} children={<Layout />} />}>
                    <Route path={RoutesEnum.CITIES} element={<CitiesPage />} />
                    <Route path={RoutesEnum.CITIES_EDIT} element={<CitiesEditPage />} />
                    <Route path={RoutesEnum.CITIES_CREATE} element={<CitiesCreatePage />} />
                    <Route path={RoutesEnum.DISTRICTS} element={<DistrictsPage />} />
                    <Route path={RoutesEnum.DISTRICTS_EDIT} element={<DistrictsEditPage />} />
                    <Route path={RoutesEnum.DISTRICTS_CREATE} element={<DistrictsCreatePage />} />
                    <Route path={RoutesEnum.CURRENCIES} element={<CurrenciesPage />} />
                    <Route path={RoutesEnum.CURRENCIES_EDIT} element={<CurrenciesEditPage />} />
                    <Route path={RoutesEnum.CURRENCIES_CREATE} element={<CurrenciesCreatePage />} />
                    <Route path={RoutesEnum.PRODUCT_UNITS} element={<ProductUnitPage />} />
                    <Route path={RoutesEnum.PRODUCT_UNITS_CREATE} element={<ProductUnitCreatePage />} />
                    <Route path={RoutesEnum.PRODUCT_UNITS_EDIT} element={<ProductUnitEditPage />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </DrawerProvider>
          </TokenRefresherProvider>
        </AuthProvider>
      </QueryClientProvider>
    </ChakraProvider>
  )
}

const rootElement = document.getElementById('root')
if (rootElement) {
  createRoot(rootElement).render(<AppWrapper />)
} else {
  console.error('Root element not found')
}

reportWebVitals()
