import JSPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { TableLayoutProps } from '../components/data-table/data-table.component.types'
import moment from 'moment-timezone'
import robotoRegularFontBase64 from '../constants/roboto-regular-font.constant'

const getValueByPath = (row: any, path: string): any => {
  return path.split('.').reduce((prev, curr) => prev?.[curr], row)
}

const exportDataToPDF = ({ data, columns }: Pick<TableLayoutProps, 'data' | 'columns'>): void => {
  const doc = new JSPDF({ compress: true, putOnlyUsedFonts: true })

  doc.addFileToVFS('Roboto-Regular.ttf', robotoRegularFontBase64)
  doc.addFont('Roboto-Regular.ttf', 'Roboto-Regular', 'normal')
  doc.setFont('Roboto-Regular', 'normal')
  doc.setFontSize(12)

  autoTable(doc, {
    head: [columns.filter(col => !col.hidden).map(col => col.title)],
    body: data.map(row =>
      columns
        .filter(col => !col.hidden)
        .map(col => {
          const value = col.key.includes('.') ? getValueByPath(row, col.key) : (row as any)[col.key]
          return value?.toString() || ''
        })
    ),
    startY: 20,
    styles: { font: 'Roboto-Regular', fontStyle: 'normal', fontSize: 10 },
  })

  doc.save(`table-data-${moment().format('YYYY-MM-DD')}.pdf`)
}

export default exportDataToPDF
