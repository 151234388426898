import React, { forwardRef, KeyboardEvent, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { MyAccountApi } from '../../utils/api-service.util'
import { useNavigate } from 'react-router-dom'
import RoutesEnum from '../../enums/routes.enum'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import * as Yup from 'yup'
import { useFormik } from 'formik'

const ChangePasswordPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'changePassword' })
  const navigate = useNavigate()
  const toast = useToast()

  const currentPasswordDisclosure = useDisclosure()
  const newPasswordDisclosure = useDisclosure()
  const newPasswordConfirmationDisclosure = useDisclosure()
  const currentPasswordInputRef = useRef<HTMLInputElement>(null)
  const newPasswordInputRef = useRef<HTMLInputElement>(null)
  const newPasswordConfirmationInputRef = useRef<HTMLInputElement>(null)

  const changePasswordMutation = useMutation({
    mutationKey: [FormSchemaTypes.ChangePasswordCommand],
    mutationFn: MyAccountApi.apiMyAccountChangePasswordPatch,
    onSuccess: () => {
      toast({
        title: t('changePasswordSuccessTitle'),
        description: t('changePasswordSuccessDescription'),
      })
      navigate(RoutesEnum.HOME)
    },
    onError: () => {
      toast({
        title: t('changePasswordFailedTitle'),
        description: t('changePasswordFailedDescription'),
        status: 'error',
      })
    },
  })

  const validationSchema = Yup.object({
    currentPassword: Yup.string().min(6, t('currentPasswordInvalid')).required(t('currentPasswordRequired')),
    newPassword: Yup.string().min(6, t('newPasswordInvalid')).required(t('newPasswordRequired')),
    newPasswordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword'), ''], t('confirmNewPasswordInvalid'))
      .required(t('confirmNewPasswordRequired')),
  })

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    },
    validationSchema,
    onSubmit: values => {
      changePasswordMutation.mutate({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
    },
  })

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (!formik.values.currentPassword) {
        currentPasswordInputRef.current?.focus()
        return
      }

      if (!formik.values.newPassword) {
        newPasswordInputRef.current?.focus()
        return
      }

      if (!formik.values.newPasswordConfirmation) {
        newPasswordConfirmationInputRef.current?.focus()
        return
      }
    }
  }

  const boxBg = useColorModeValue('gray.50', 'gray.700')

  return (
    <Box mt={4}>
      <form onSubmit={formik.handleSubmit}>
        <VStack spacing="8">
          <Heading textAlign="center">{t('title')}</Heading>
          <Box bg={boxBg} boxShadow="md" p="8" borderRadius="xl" w="100%" maxW="30rem">
            <Stack spacing="6">
              <PasswordInputField
                id="currentPassword"
                label={t('currentPassword')}
                isOpen={currentPasswordDisclosure.isOpen}
                onToggle={currentPasswordDisclosure.onToggle}
                formik={formik}
                ref={currentPasswordInputRef}
                onKeyDown={handleKeyDown}
                autoComplete="current-password"
              />

              <PasswordInputField
                id="newPassword"
                label={t('newPassword')}
                isOpen={newPasswordDisclosure.isOpen}
                onToggle={newPasswordDisclosure.onToggle}
                formik={formik}
                ref={newPasswordInputRef}
                onKeyDown={handleKeyDown}
                autoComplete="new-password"
              />

              <PasswordInputField
                id="newPasswordConfirmation"
                label={t('confirmNewPassword')}
                isOpen={newPasswordConfirmationDisclosure.isOpen}
                onToggle={newPasswordConfirmationDisclosure.onToggle}
                formik={formik}
                ref={newPasswordConfirmationInputRef}
                onKeyDown={handleKeyDown}
                autoComplete="new-password"
              />
              <Button isLoading={changePasswordMutation.isPending} loadingText={t('submitting')} colorScheme="blue" type="submit" isDisabled={!formik.isValid || !formik.dirty}>
                {t('submit')}
              </Button>
            </Stack>
          </Box>
        </VStack>
      </form>
    </Box>
  )
}

const PasswordInputField = forwardRef(
  (
    {
      id,
      label,
      isOpen,
      onToggle,
      formik,
      onKeyDown,
      autoComplete,
    }: {
      id: string
      label: string
      isOpen: boolean
      onToggle: () => void
      formik: any
      onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void
      autoComplete?: string
    },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => (
    <FormControl isInvalid={Boolean(formik.errors[id] && formik.touched[id])}>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <InputGroup>
        <Input id={id} type={isOpen ? 'text' : 'password'} {...formik.getFieldProps(id)} ref={ref} onKeyDown={onKeyDown} autoComplete={autoComplete} />
        <InputRightElement>
          <IconButton aria-label={isOpen ? 'Mask password' : 'Reveal password'} icon={isOpen ? <HiEyeOff /> : <HiEye />} onClick={onToggle} variant="ghost" />
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{formik.errors[id]}</FormErrorMessage>
    </FormControl>
  )
)

export default ChangePasswordPage
