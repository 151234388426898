import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Center, Spinner } from '@chakra-ui/react'
import { useAuth } from '../../contexes/auth/auth.context'
import { AuthStatus } from '../../contexes/auth/auth.context.types'
import Routes from '../../enums/routes.enum'

const AuthenticatedRedirect = () => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.loadingStatus === AuthStatus.AUTHENTICATED) {
    return <Navigate to={Routes.HOME} state={{ from: location }} replace />
  }

  if (auth.loadingStatus !== AuthStatus.UNAUTHENTICATED) {
    return (
      <Center height="calc(100dvh - 12rem)">
        <Spinner />
      </Center>
    )
  }

  return <Outlet />
}

export default AuthenticatedRedirect
