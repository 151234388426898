import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  PinInput,
  PinInputField,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useMutation } from '@tanstack/react-query'
import { MyAccountApi } from '../../utils/api-service.util'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import Routes from '../../enums/routes.enum'
import moment from 'moment'

type VerifyPasswordForgottenPageProps = {
  email: string
}

const VerifyPasswordForgottenPage: React.FC<VerifyPasswordForgottenPageProps> = ({ email }) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { isOpen, onToggle } = useDisclosure()
  const boxBg = useColorModeValue('gray.50', 'gray.700')
  const passwordInputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation(undefined, { keyPrefix: 'verifyPasswordForgotten' })

  const validationSchema = Yup.object({
    email: Yup.string().email(t('emailInvalid')).required(t('emailRequired')),
    resetCode: Yup.string(),
    newPassword: Yup.string().min(6, t('passwordInvalid')).required(t('passwordRequired')),
  })

  const formik = useFormik({
    initialValues: {
      email: email,
      resetCode: '',
      newPassword: '',
    },
    validationSchema,
    onSubmit: values => {
      verifyMutation.mutate(values)
    },
  })

  const resendMailMutation = useMutation({
    mutationKey: [FormSchemaTypes.ForgotPasswordCommand],
    mutationFn: MyAccountApi.apiMyAccountForgotPasswordPost,
    onSuccess: () => {
      toast({
        title: t('resendSuccessTitle'),
        description: t('resendSuccessDescription'),
        status: 'success',
      })
      restartTimer()
    },
    onError: () => {
      toast({
        title: t('resendFailedTitle'),
        description: t('resendFailedDescription'),
        status: 'error',
      })
    },
  })

  const verifyMutation = useMutation({
    mutationFn: MyAccountApi.apiMyAccountVerifyPasswordResetPost,
    mutationKey: [FormSchemaTypes.VerifyPasswordResetCommand],
    onSuccess: () => {
      toast({
        title: t('sendSuccessTitle'),
        description: t('sendSuccessDescription'),
        status: 'success',
      })
      navigate(Routes.LOGIN)
    },
    onError: () => {
      toast({
        title: t('sendFailedTitle'),
        description: t('sendFailedDescription'),
        status: 'error',
      })
    },
  })

  const [timeLeft, setTimeLeft] = useState(moment.duration(1, 'minutes'))

  useEffect(() => {
    if (timeLeft.asSeconds() <= 0) return

    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        return moment.duration(prevTime.asSeconds() - 1, 'seconds')
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [timeLeft])

  const formatTime = (time: moment.Duration) => {
    return `${time.minutes()}:${time.seconds().toString().padStart(2, '0')}`
  }

  const restartTimer = () => {
    setTimeLeft(moment.duration(1, 'minutes'))
  }

  const resendButtonOnClick = () => {
    resendMailMutation.mutate({ email })
  }

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '4', md: '4' }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing="8">
          <Heading textAlign="center">{t('title')}</Heading>
          <Text textAlign="center">{t('detail')}</Text>
          <Box bg={boxBg} boxShadow="md" p="8" borderRadius="xl">
            <Stack spacing="6">
              <FormControl>
                <FormLabel htmlFor="email">{t('emailLabel')}</FormLabel>
                <Input {...formik.getFieldProps('email')} disabled id="email" type="email" value={email || ''} />
              </FormControl>

              <FormControl>
                <FormLabel htmlFor="code">{t('codeLabel')}</FormLabel>
                <HStack>
                  <PinInput otp id="resetCode" onChange={value => formik.setFieldValue('resetCode', value)}>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                  <Text>0{formatTime(timeLeft)}</Text>
                </HStack>
              </FormControl>

              <FormControl isInvalid={Boolean(formik.errors.newPassword && formik.touched.newPassword)}>
                <FormLabel htmlFor="password">{t('passwordLabel')}</FormLabel>
                <InputGroup>
                  <Input id="password" type={isOpen ? 'text' : 'password'} ref={passwordInputRef} {...formik.getFieldProps('newPassword')} autoComplete="current-password" />
                  <InputRightElement>
                    <IconButton
                      aria-label={isOpen ? 'Mask password' : 'Reveal password'}
                      variant="ghost"
                      colorScheme="blue"
                      borderLeftRadius={0}
                      icon={isOpen ? <HiEyeOff /> : <HiEye />}
                      onClick={onToggle}
                    />
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
              </FormControl>

              {timeLeft.asSeconds() > 0 ? (
                <Button isLoading={verifyMutation.isPending} loadingText={t('wait')} colorScheme="blue" type="submit" isDisabled={!formik.isValid}>
                  {t('finish')}
                </Button>
              ) : (
                <Button isLoading={verifyMutation.isPending} loadingText={t('wait')} colorScheme="orange" onClick={resendButtonOnClick}>
                  {t('resendCode')}
                </Button>
              )}
            </Stack>
          </Box>
        </Stack>
      </form>
    </Container>
  )
}

export default VerifyPasswordForgottenPage
