import React, { FC } from 'react'
import Pagination from '../pagination/pagination.component'
import { Center } from '@chakra-ui/react'
import { PaginationControlProps } from './data-table.component.types'

const PaginationControl: FC<PaginationControlProps> = ({ isPaginationShown, isPaginationDisabled, currentPage, totalPageCount, onPageChange, pageSize }) => {
  if (!isPaginationShown) {
    return null
  }

  return (
    <Center>
      <Pagination currentPage={currentPage} totalPageCount={totalPageCount} pageSize={pageSize} onPageChange={onPageChange} isPaginationDisabled={isPaginationDisabled} />
    </Center>
  )
}

export default PaginationControl
