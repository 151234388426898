import { useTranslation } from 'react-i18next'
import { Box, Heading, useToast } from '@chakra-ui/react'
import { Form, UiSchema } from '@rjsf/chakra-ui'
import schema from '../../utils/form-schema.util'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import validator from '@rjsf/validator-ajv8'
import React from 'react'
import { useMutation } from '@tanstack/react-query'
import { ProductCategoryApi } from '../../utils/api-service.util'
import RoutesEnum from '../../enums/routes.enum'
import { useNavigate } from 'react-router-dom'
import fixupFormStrings, { fixupFormErrorStrings } from '../../utils/fixup-form-strings.util'

const ProductCategoriesCreatePage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'productCategories' })
  const navigate = useNavigate()
  const toast = useToast()

  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      props: {
        disabled: false,
      },
      submitText: t('submit'),
    },
    schema: {
      name: {
        'ui:title': t('name'),
      },
    },
  }

  const submitMutation = useMutation({
    mutationKey: [FormSchemaTypes.CreateProductCategoryCommand],
    mutationFn: ProductCategoryApi.apiProductCategoryPost,
    onSuccess: () => {
      toast({
        title: t('createSuccess'),
        status: 'success',
      })
      navigate(RoutesEnum.PRODUCT_CATEGORIES)
    },
    onError: () => {
      toast({
        title: t('createFailed'),
        status: 'error',
      })
    },
  })

  return (
    <Box width="100%" display="flex" mt={15} flexDirection="column" alignItems="center">
      <Heading as="h1" size="xl" textAlign="center" mb={5}>
        {t('create')}
      </Heading>
      <Box width="full" maxWidth="lg">
        <Form
          schema={schema('', FormSchemaTypes.CreateProductCategoryCommand)}
          onSubmit={data => submitMutation.mutate(data.formData.schema)}
          validator={validator}
          uiSchema={uiSchema}
          showErrorList={false}
          noHtml5Validate
          focusOnFirstError
          translateString={fixupFormStrings}
          transformErrors={fixupFormErrorStrings}
        />
      </Box>
    </Box>
  )
}

export default ProductCategoriesCreatePage
