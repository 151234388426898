import React, { FC } from 'react'
import { NoDataRowProps } from './data-table.component.types'
import { Td, Tr } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

const NoDataRow: FC<NoDataRowProps> = ({ colSpan }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'dataTable' })

  return (
    <Tr>
      <Td colSpan={colSpan} textAlign="center">
        {t('noDataAvailable')}
      </Td>
    </Tr>
  )
}

export default NoDataRow
