import React from 'react'
import { Button, HStack, Text } from '@chakra-ui/react'
import PaginationProps from './pagination.component.types'

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPageCount, isPaginationDisabled, pageSize, onPageChange, ...props }) => {
  const generatePageNumbers = () => {
    let pages: (number | string)[] = []
    let startPage: number, endPage: number

    if (totalPageCount <= 5) {
      startPage = 1
      endPage = totalPageCount
    } else {
      if (currentPage <= 3) {
        startPage = 1
        endPage = 5
      } else if (currentPage + 2 >= totalPageCount) {
        startPage = totalPageCount - 4
        endPage = totalPageCount
      } else {
        startPage = currentPage - 2
        endPage = currentPage + 2
      }
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(page)
    }

    if (startPage > 2) {
      pages.unshift('...')
      pages.unshift(1)
    } else if (startPage === 2) {
      pages.unshift(1)
    }

    if (endPage < totalPageCount - 1) {
      pages.push('...')
      pages.push(totalPageCount)
    } else if (endPage === totalPageCount - 1) {
      pages.push(totalPageCount)
    }

    return pages
  }

  const pageNumbers = generatePageNumbers()

  return (
    <HStack spacing={2} flexWrap="wrap" {...props}>
      {pageNumbers.map((page, index) =>
        typeof page === 'number' ? (
          <Button key={index} variant="outline" rounded="full" p={0} isActive={currentPage === page} onClick={() => onPageChange?.(page)} isDisabled={isPaginationDisabled}>
            {page}
          </Button>
        ) : (
          <Text key={index} as="span" fontSize="lg" userSelect="none">
            {page}
          </Text>
        )
      )}
    </HStack>
  )
}

export default Pagination
