import { useTranslation } from 'react-i18next'
import { Box, Center, Heading, Spinner, Text, useToast } from '@chakra-ui/react'
import { Form, UiSchema } from '@rjsf/chakra-ui'
import schema from '../../utils/form-schema.util'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import validator from '@rjsf/validator-ajv8'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { DistrictApi } from '../../utils/api-service.util'
import { useParams } from 'react-router-dom'
import CitySelectWidget from '../../components/city-select-widget/city-select-widget.component'
import fixupFormStrings, { fixupFormErrorStrings } from '../../utils/fixup-form-strings.util'

const DistrictsEditPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'districts' })
  const toast = useToast()
  const [formData, setFormData] = React.useState<object | undefined>()
  const { id } = useParams()

  const districtQuery = useQuery({
    queryKey: [FormSchemaTypes.GetDistrictQueryResult, id],
    queryFn: () => DistrictApi.apiDistrictGet(id ?? ''),
    enabled: !!id,
  })

  useEffect(() => {
    if (districtQuery.data?.data) {
      const { id, name, city } = districtQuery.data.data
      setFormData({
        schema: {
          id,
          name,
          cityId: city.id,
        },
      })
    }
  }, [districtQuery.data?.data])

  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      submitText: t('update'),
    },
    schema: {
      id: {
        'ui:title': t('id'),
        'ui:readonly': true,
      },
      name: {
        'ui:title': t('name'),
      },
      cityId: {
        'ui:widget': CitySelectWidget,
        'ui:title': t('cityId'),
      },
    },
  }

  const updateMutation = useMutation({
    mutationFn: DistrictApi.apiDistrictPatch,
    onSuccess: () => {
      toast({
        title: t('updateSuccess'),
        status: 'success',
      })
    },
    onError: () => {
      toast({
        title: t('updateFailed'),
        status: 'error',
      })
    },
  })

  if (districtQuery.isError) {
    return (
      <Center height="calc(100dvh - 10rem)">
        <Text color="red.500">{t('fetchFailed')}</Text>
      </Center>
    )
  }

  if (districtQuery.isFetching || !formData) {
    return (
      <Center height="calc(100dvh - 10rem)">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box width="100%" display="flex" mt={15} flexDirection="column" alignItems="center">
      <Heading mb={4}>{t('edit')}</Heading>
      <Box width="full" maxWidth="lg">
        <Form
          formData={formData}
          schema={schema('', FormSchemaTypes.UpdateDistrictCommand)}
          onChange={data => setFormData(data.formData)}
          onSubmit={data => updateMutation.mutate(data.formData.schema)}
          validator={validator}
          uiSchema={uiSchema}
          showErrorList={false}
          noHtml5Validate
          focusOnFirstError
          translateString={fixupFormStrings}
          transformErrors={fixupFormErrorStrings}
        />
      </Box>
    </Box>
  )
}

export default DistrictsEditPage
