enum Routes {
  HOME = '/',
  LOGIN = '/login',
  RESTRICTED = '/restricted',
  FORGET_PASSWORD = '/forget-password',
  CHANGE_PASSWORD = '/change-password',
  TRADING_PARTNERS = '/trading-partners',
  TRADING_PARTNERS_CREATE = '/trading-partners/create',
  TRADING_PARTNERS_EDIT = '/trading-partners/edit/:id',
  PRODUCTS = '/products',
  PRODUCTS_CREATE = '/products/create',
  PRODUCTS_EDIT = '/products/edit/:id',
  PRODUCT_CATEGORIES = '/product-categories',
  PRODUCT_CATEGORY_CREATE = '/product-category/create',
  PRODUCT_CATEGORY_EDIT = '/product-category/edit/:id',
  ACCOUNTS = '/accounts',
  ACCOUNTS_CREATE = '/accounts/create',
  ACCOUNTS_EDIT = '/accounts/edit/:id',
  CITIES = '/cities',
  CITIES_CREATE = '/cities/create',
  CITIES_EDIT = '/cities/edit/:id',
  DISTRICTS = '/districts',
  DISTRICTS_CREATE = '/districts/create',
  DISTRICTS_EDIT = '/districts/edit/:id',
  OFFERS = '/offers',
  OFFERS_CREATE = '/offers/create',
  OFFERS_EDIT = '/offers/edit/:id',
  OFFER_CATEGORIES = '/offer-categories',
  OFFER_CATEGORY_CREATE = '/offer-category/create',
  OFFER_CATEGORY_EDIT = '/offer-category/edit/:id',
  ORDERS = '/orders',
  ORDERS_CREATE = '/orders/create',
  ORDERS_EDIT = '/orders/edit/:id',
  ORDER_CATEGORY = '/order-category',
  ORDER_CATEGORY_CREATE = '/order-category/create',
  ORDER_CATEGORY_EDIT = '/order-category/edit/:id',
  CURRENCIES = '/currencies',
  CURRENCIES_CREATE = '/currencies/create',
  CURRENCIES_EDIT = '/currencies/edit/:id',
  PRODUCT_UNITS = '/product-units',
  PRODUCT_UNITS_CREATE = '/product-units/create',
  PRODUCT_UNITS_EDIT = '/product-units/edit/:id',
}

export default Routes
