import * as Services from '@vennyx-org/erp-api-client'
import axios, { InternalAxiosRequestConfig } from 'axios'
import AppConfig from '../configs/app.config'
import LocalStorageKeys from '../enums/local-storage-keys.enum'
import AuthTokens from '../declerations/auth-tokens.decleration'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0'

const customAxios = axios.create({
  baseURL: AppConfig.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
})

const onRequest = (req: InternalAxiosRequestConfig) => {
  const authKey = localStorage.getItem(LocalStorageKeys.AUTH)

  if (authKey) {
    try {
      const { token } = JSON.parse(authKey) as AuthTokens

      if (token && req.headers) {
        req.headers.Authorization = `Bearer ${token}`
      }
    } catch {}
  }

  return req
}

customAxios.interceptors.request.use(onRequest)

export const AccountApi = Services.AccountApiFactory(undefined, undefined, customAxios)
export const CityApi = Services.CityApiFactory(undefined, undefined, customAxios)
export const CurrencyApi = Services.CurrencyApiFactory(undefined, undefined, customAxios)
export const DistrictApi = Services.DistrictApiFactory(undefined, undefined, customAxios)
export const MyAccountApi = Services.MyAccountApiFactory(undefined, undefined, customAxios)
export const OfferApi = Services.OfferApiFactory(undefined, undefined, customAxios)
export const OfferCategoryApi = Services.OfferCategoryApiFactory(undefined, undefined, customAxios)
export const OrderApi = Services.OrderApiFactory(undefined, undefined, customAxios)
export const OrderCategoryApi = Services.OrderCategoryApiFactory(undefined, undefined, customAxios)
export const ProductApi = Services.ProductApiFactory(undefined, undefined, customAxios)
export const ProductCategoryApi = Services.ProductCategoryApiFactory(undefined, undefined, customAxios)
export const ProductUnitApi = Services.ProductUnitApiFactory(undefined, undefined, customAxios)
export const TradingPartnerApi = Services.TradingPartnerApiFactory(undefined, undefined, customAxios)
