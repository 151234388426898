import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Heading, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import Routes from '../../enums/routes.enum'
import { Link } from 'react-router-dom'
import { MdOutlineLocalOffer, MdOutlineManageAccounts, MdOutlineShoppingCart, MdSupervisorAccount } from 'react-icons/md'
import { FiLayers } from 'react-icons/fi'
import UserRoles from '../../enums/user-roles.enum'
import { userHasAnyRole } from '../../utils/user-role.util'
import { TbCategory } from 'react-icons/tb'
import { BiBuildingHouse, BiMoney, BiSolidCity, BiUnite } from 'react-icons/bi'
import { RiListUnordered } from 'react-icons/ri'

const HomePage: FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'home' })

  const buttons = useMemo(
    () => [
      {
        path: Routes.TRADING_PARTNERS,
        label: t('tradingPartners'),
        icon: MdSupervisorAccount,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.PRODUCTS,
        label: t('products'),
        icon: MdOutlineShoppingCart,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.PRODUCT_CATEGORIES,
        label: t('productCategories'),
        icon: FiLayers,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.OFFERS,
        label: t('offers'),
        icon: MdOutlineLocalOffer,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.OFFER_CATEGORIES,
        label: t('offersCategory'),
        icon: TbCategory,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.ORDERS,
        label: t('orders'),
        icon: RiListUnordered,
        roles: [UserRoles.USER, UserRoles.MANAGER, UserRoles.OWNER],
      },
      {
        path: Routes.ORDER_CATEGORY,
        label: t('orderCategories'),
        icon: TbCategory,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.ACCOUNTS,
        label: t('accounts'),
        icon: MdOutlineManageAccounts,
        roles: [UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.CITIES,
        label: t('cities'),
        icon: BiSolidCity,
        roles: [UserRoles.OWNER],
      },
      {
        path: Routes.DISTRICTS,
        label: t('districts'),
        icon: BiBuildingHouse,
        roles: [UserRoles.OWNER],
      },
      {
        path: Routes.CURRENCIES,
        label: t('currencies'),
        icon: BiMoney,
        roles: [UserRoles.OWNER],
      },
      {
        path: Routes.PRODUCT_UNITS,
        label: t('productUnits'),
        icon: BiUnite,
        roles: [UserRoles.OWNER],
      },
    ],
    [t]
  )

  const boxBg = useColorModeValue('gray.50', 'gray.700')

  return (
    <VStack mt={4} spacing={4} alignItems="flex-start">
      <Heading>{t('title')}</Heading>
      <Text>{t('description')}</Text>
      <Flex w="100%" justifyContent="center" flexWrap="wrap" gap={4} mt={8}>
        {buttons
          .filter(button => userHasAnyRole(button.roles))
          .map((button, index) => (
            <Link key={index} to={button.path}>
              <VStack spacing={4} p={4} _hover={{ bg: 'gray.400' }} width="12rem" height="12rem" justifyContent="center" boxShadow="md" borderRadius="lg" bg={boxBg}>
                <button.icon size={40} />
                <Text fontSize="lg" textAlign="center">
                  {button.label}
                </Text>
              </VStack>
            </Link>
          ))}
      </Flex>
    </VStack>
  )
}

export default HomePage
