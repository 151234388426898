import React, { FC, useState } from 'react'
import { Table, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { SortDirection, SortState, TableLayoutProps } from './data-table.component.types'
import SkeletonRows from './skeleton-rows.component'
import DataRow from './data-row.component'
import NoDataRow from './no-data-row.component'
import { useTranslation } from 'react-i18next'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'

const TableLayout: FC<TableLayoutProps> = ({
  isLoading,
  columns,
  data,
  onRowDelete,
  onRowEditClick,
  onRowDetailClick,
  onRowConvertToOrderClick,
  onRowCompleteOrderClick,
  onRowClick,
  onSort,
  defaultSort,
  enableMultipleSort,
  isRowDeleting,
  isOfferConverting,
  isOrderCompleting,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'dataTable' })
  const [sortState, setSortState] = useState<SortState>(defaultSort || {})

  const handleSort = (column: string) => {
    const direction =
      sortState[column] === SortDirection.ASCENDING ? SortDirection.DESCENDING : sortState[column] === SortDirection.DESCENDING ? SortDirection.NONE : SortDirection.ASCENDING

    let computedSortState = { [column]: direction }
    if (enableMultipleSort) {
      computedSortState = { ...sortState, [column]: direction }
    }

    setSortState(computedSortState)
    onSort?.(column, direction)
  }

  const renderSortIcon = (column: string) => {
    const direction = sortState[column]
    if (direction === SortDirection.ASCENDING) return <FaSortUp />
    if (direction === SortDirection.DESCENDING) return <FaSortDown />
    return <FaSort />
  }

  return (
    <TableContainer>
      <Table variant="striped" size="sm">
        <Thead>
          <Tr>
            {columns
              .filter(column => !column.hidden)
              .map(column => (
                <Th
                  key={column.key}
                  onClick={() => column.sortable && handleSort(column.key)}
                  verticalAlign="top"
                  cursor={column.sortable ? 'pointer' : 'default'}
                  _hover={{ color: column.sortable ? 'teal' : 'inherit' }}
                >
                  {column.title}
                  {column.sortable && renderSortIcon(column.key)}
                </Th>
              ))}
            <Th width="0" textAlign="right" verticalAlign="top">
              {t('actions')}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading ? (
            <SkeletonRows rowCount={5} columnCount={columns.length + 1} />
          ) : data.length > 0 ? (
            data.map((row, index) => (
              <DataRow
                key={index}
                row={row}
                columns={columns}
                onRowDelete={onRowDelete}
                onRowDetailClick={onRowDetailClick}
                onRowClick={onRowClick}
                onRowEditClick={() => onRowEditClick?.(row)}
                onRowConvertToOrderClick={() => onRowConvertToOrderClick?.(row)}
                onRowCompleteOrderClick={() => onRowCompleteOrderClick?.(row)}
                isRowDeleting={isRowDeleting}
                isOfferConverting={isOfferConverting}
                isOrderCompleting={isOrderCompleting}
              />
            ))
          ) : (
            <NoDataRow colSpan={columns.length + 1} />
          )}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default TableLayout
