import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import AuthTokens from '../../declerations/auth-tokens.decleration'
import { decodeToken, isExpired } from 'react-jwt'
import JWTToken from '../../declerations/jwt-token.decleration'
import { AuthContextProps, AuthStatus } from './auth.context.types'
import LocalStorageKeys from '../../enums/local-storage-keys.enum'
import { AuthContext } from './auth.context'

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loadingStatus, setLoadingStatus] = useState(AuthStatus.LOADING)
  const [authTokens, setAuthTokens] = useState<AuthTokens | null>(null)
  const decodedToken = useMemo(() => decodeToken<JWTToken>(authTokens?.token ?? ''), [authTokens])
  const roles = useMemo(() => decodedToken?.role ?? [], [decodedToken])

  const updateAuthStateWithToken = (auth: string | null) => {
    if (auth) {
      try {
        const authKey = JSON.parse(auth) as AuthTokens
        const token = authKey.token

        if (token && !isExpired(token)) {
          setLoadingStatus(AuthStatus.AUTHENTICATED)
          setAuthTokens(authKey)
          return
        }
      } catch {
        console.log('Token validation failed')
      }
    }
    localStorage.removeItem(LocalStorageKeys.AUTH)
    setLoadingStatus(AuthStatus.UNAUTHENTICATED)
    setAuthTokens(null)
  }

  const loadAuthFromLocalStorage = useCallback(() => {
    const localStorageAuth = localStorage.getItem(LocalStorageKeys.AUTH)
    updateAuthStateWithToken(localStorageAuth)
  }, [])

  useEffect(() => {
    loadAuthFromLocalStorage()
    window.addEventListener('storage', loadAuthFromLocalStorage)

    return () => {
      window.removeEventListener('storage', loadAuthFromLocalStorage)
    }
  }, [loadAuthFromLocalStorage])

  const changeLoadingStatus = useCallback((status: AuthStatus) => {
    setLoadingStatus(status)
  }, [])

  const updateAuthStatus = useCallback((auth: string) => {
    localStorage.setItem(LocalStorageKeys.AUTH, auth)
    updateAuthStateWithToken(auth)
  }, [])

  const logout = useCallback(() => {
    updateAuthStateWithToken(null)
  }, [])

  const value: AuthContextProps = useMemo(
    () => ({
      roles,
      loadingStatus,
      authTokens,
      decodedToken,
      changeLoadingStatus,
      updateAuthStatus,
      logout,
    }),
    [roles, loadingStatus, authTokens, decodedToken, changeLoadingStatus, updateAuthStatus, logout]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider
