import React, { ReactNode, useEffect, useMemo, useRef } from 'react'
import { useAuth } from '../auth/auth.context'
import { useMutation } from '@tanstack/react-query'
import { MyAccountApi } from '../../utils/api-service.util'
import RoutesEnum from '../../enums/routes.enum'
import { AuthStatus } from '../auth/auth.context.types'
import { decodeToken } from 'react-jwt'
import JWTToken from '../../declerations/jwt-token.decleration'
import { TokenRefresherContextProps } from './token-refresher.context.types'
import { TokenRefresherContext } from './token-refresher.context'
import FormSchemaTypes from '../../enums/form-schema-types.enum'

const TokenRefresherProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth()
  let timerRef = useRef<NodeJS.Timeout | null>(null)

  const refreshTokenMutation = useMutation({
    mutationFn: MyAccountApi.apiMyAccountRefreshPost,
    mutationKey: [FormSchemaTypes.RefreshCommand],
    onSuccess: ({ data }) => {
      auth.updateAuthStatus(JSON.stringify(data))
    },
    onError: () => {
      auth.logout()
      window.location.href = RoutesEnum.LOGIN
    },
  })

  useEffect(() => {
    if (!auth.authTokens?.token || auth.loadingStatus !== AuthStatus.AUTHENTICATED) {
      return
    }

    const decodedToken = decodeToken<JWTToken>(auth.authTokens.token)
    if (!decodedToken) {
      return
    }

    const expTime = decodedToken.exp * 1000
    const currentTime = Date.now()

    if (currentTime >= expTime) {
      refreshTokenMutation.mutate({ refreshToken: auth.authTokens.refreshToken })
    } else {
      const expectedExpTime = (expTime - currentTime) * 0.8
      timerRef.current = setTimeout(() => {
        refreshTokenMutation.mutate({ refreshToken: auth.authTokens?.refreshToken ?? '' })
      }, expectedExpTime)
    }

    return () => clearTimeout(timerRef.current as NodeJS.Timeout)
  }, [auth.authTokens, auth.loadingStatus, refreshTokenMutation])

  const value: TokenRefresherContextProps = useMemo(() => ({}), [])

  return <TokenRefresherContext.Provider value={value}>{children}</TokenRefresherContext.Provider>
}

export default TokenRefresherProvider
