import UserRoles from '../enums/user-roles.enum'
import { decodeToken } from 'react-jwt'
import localStorageKeysEnum from '../enums/local-storage-keys.enum'
import JWTToken from '../declerations/jwt-token.decleration'
import AuthTokens from '../declerations/auth-tokens.decleration'

const getToken = () => {
  const authKey = localStorage.getItem(localStorageKeysEnum.AUTH)
  if (!authKey) return null
  try {
    return (JSON.parse(authKey) as AuthTokens).token
  } catch {
    return null
  }
}

const getRoles = () => decodeToken<JWTToken>(getToken() ?? '')?.role ?? []

export const userHasRole = (role: UserRoles): boolean => {
  const userRoles = getRoles()
  return userRoles.includes(role)
}

export const userHasRoles = (roles: UserRoles[]): boolean => {
  const userRoles = getRoles()
  return roles.every(role => userRoles.includes(role))
}

export const userHasAnyRole = (roles: UserRoles[]): boolean => {
  const userRoles = getRoles()
  return roles.some(role => userRoles.includes(role))
}
