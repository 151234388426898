import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from '@rjsf/chakra-ui'
import { RJSFSchema } from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { Box, Heading, useToast } from '@chakra-ui/react'
import { ProductListItem } from '@vennyx-org/erp-api-client'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import RoutesEnum from '../../enums/routes.enum'
import schema from '../../utils/form-schema.util'
import ProductUnitSelectWidget from '../../components/product-unit-select-widget/product-unit-select-widget.component'
import CurrencySelectWidget from '../../components/currency-select-widget/currency-select.widget.component'
import TradingPartnerSelectWidget from '../../components/trading-partner-select-widget/trading-partner-select-widget.component'
import ProductSelectWidget from '../../components/product-select-widget/product-select-widget.component'
import fixupFormStrings, { fixupFormErrorStrings } from '../../utils/fixup-form-strings.util'
import { OrderApi } from '../../utils/api-service.util'
import OrderCategorySelectWidget from '../../components/order-category-select-widget/order-category-select-widget.component'

const OrderCreatePage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'orders' })
  const toast = useToast()
  const navigate = useNavigate()
  const [formData, setFormData] = React.useState<RJSFSchema>({ schema: {} })
  const [selectedProducts, setSelectedProducts] = React.useState<ProductListItem[]>([])

  const handleProductSelect = useCallback((product: ProductListItem) => {
    setSelectedProducts(prevProducts => {
      if (prevProducts.some(p => p.id === product.id)) {
        return prevProducts
      }
      return [...prevProducts, product]
    })
  }, [])

  useEffect(() => {
    const formDataDetails = formData.schema.details || []
    if (formDataDetails.length > 0) {
      const unchangedDetails = formDataDetails.filter((detail: any) => {
        const product = selectedProducts.find(p => p.id === detail.productId)
        return product && product.code !== detail.productCode && product.name !== detail.productName
      })

      if (unchangedDetails.length > 0) {
        setFormData(prevFormData => {
          const updatedDetails = prevFormData.schema.details.map((detail: any) => {
            const product = selectedProducts.find(p => p.id === detail.productId)
            if (product && product.code !== detail.productCode && product.name !== detail.productName) {
              return {
                ...detail,
                productCode: product.code,
                productName: product.name,
              }
            }
            return detail
          })
          return { ...prevFormData, schema: { ...prevFormData.schema, details: updatedDetails } }
        })
      }
    }
  }, [selectedProducts, formData])

  const uiSchema = {
    'ui:submitButtonOptions': {
      submitText: t('submit'),
    },
    schema: {
      categoryId: {
        'ui:title': t('categoryId'),
        'ui:widget': OrderCategorySelectWidget,
      },
      tradingPartnerId: {
        'ui:title': t('tradingPartnerId'),
        'ui:widget': TradingPartnerSelectWidget,
      },
      orderDateTime: {
        'ui:title': t('orderDateTime'),
      },
      currencyId: {
        'ui:title': t('currencyId'),
        'ui:widget': CurrencySelectWidget,
      },
      description: {
        'ui:title': t('description'),
      },
      deliveryDateTime: {
        'ui:title': t('deliveryDateTime'),
      },
      details: {
        'ui:label': false,
        items: {
          'ui:title': t('productDetails'),
          productId: {
            'ui:title': t('productId'),
            'ui:widget': ProductSelectWidget,
            'ui:options': {
              onProductSelect: handleProductSelect,
            },
          },
          productCode: { 'ui:title': t('productCode'), 'ui:readonly': true },
          productName: { 'ui:title': t('productName'), 'ui:readonly': true },
          quantity: { 'ui:title': t('quantity') },
          productUnitId: { 'ui:title': t('productUnitId'), 'ui:widget': ProductUnitSelectWidget },
          price: { 'ui:title': t('price') },
          discountRate: { 'ui:title': t('discountRate') },
          vatRate: { 'ui:title': t('vatRate') },
          description: { 'ui:title': t('description') },
        },
      },
    },
  }

  const submitMutation = useMutation({
    mutationKey: [FormSchemaTypes.CreateOrderCommand],
    mutationFn: OrderApi.apiOrderPost,
    onSuccess: () => {
      toast({
        title: t('createSuccess'),
        status: 'success',
      })
      navigate(RoutesEnum.ORDERS)
    },
    onError: () => {
      toast({
        title: t('createFailed'),
        status: 'error',
      })
    },
  })

  return (
    <Box width="100%" display="flex" mt={15} flexDirection="column" alignItems="center">
      <Heading mb={4}>{t('create')}</Heading>
      <Box width="full" maxWidth="lg">
        <Form
          formData={formData}
          schema={schema('', FormSchemaTypes.CreateOrderCommand)}
          onChange={data => setFormData(data.formData)}
          onSubmit={data => submitMutation.mutate(data.formData.schema)}
          validator={validator}
          uiSchema={uiSchema}
          showErrorList={false}
          noHtml5Validate
          focusOnFirstError
          translateString={fixupFormStrings}
          transformErrors={fixupFormErrorStrings}
        />
      </Box>
    </Box>
  )
}

export default OrderCreatePage
