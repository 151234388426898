import React, { useMemo } from 'react'
import { Button, Center, Drawer as ChakraDrawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Text, useMediaQuery, VStack } from '@chakra-ui/react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import appConfig from '../../configs/app.config'
import { useDrawer } from '../../contexes/drawer/drawer.context'
import { MdOutlineChevronRight, MdOutlineHome, MdOutlineLocalOffer, MdOutlineManageAccounts, MdOutlineShoppingCart, MdSupervisorAccount } from 'react-icons/md'
import Routes from '../../enums/routes.enum'
import { FiLayers } from 'react-icons/fi'
import UserRoles from '../../enums/user-roles.enum'
import { userHasAnyRole } from '../../utils/user-role.util'
import { BiBuildingHouse, BiMoney, BiSolidCity, BiUnite } from 'react-icons/bi'
import { TbCategory } from 'react-icons/tb'
import { RiListUnordered } from 'react-icons/ri'

const Drawer = () => {
  const { isOpen, onClose } = useDrawer()
  const btnRef = React.useRef(null)
  const { t } = useTranslation(undefined, { keyPrefix: 'drawer' })
  const [isMobile] = useMediaQuery('(max-width: 767px)')
  const location = useLocation()

  const routes = useMemo(
    () => [
      {
        path: Routes.HOME,
        label: t('home'),
        icon: MdOutlineHome,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.TRADING_PARTNERS,
        label: t('tradingPartners'),
        icon: MdSupervisorAccount,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.PRODUCTS,
        label: t('products'),
        icon: MdOutlineShoppingCart,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.PRODUCT_CATEGORIES,
        label: t('productCategories'),
        icon: FiLayers,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.OFFERS,
        label: t('offers'),
        icon: MdOutlineLocalOffer,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.OFFER_CATEGORIES,
        label: t('offersCategory'),
        icon: TbCategory,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.ORDERS,
        label: t('orders'),
        icon: RiListUnordered,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.ORDER_CATEGORY,
        label: t('orderCategory'),
        icon: TbCategory,
        roles: [UserRoles.USER, UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.ACCOUNTS,
        label: t('accounts'),
        icon: MdOutlineManageAccounts,
        roles: [UserRoles.OWNER, UserRoles.MANAGER],
      },
      {
        path: Routes.CITIES,
        label: t('cities'),
        icon: BiSolidCity,
        roles: [UserRoles.OWNER],
      },
      {
        path: Routes.DISTRICTS,
        label: t('districts'),
        icon: BiBuildingHouse,
        roles: [UserRoles.OWNER],
      },
      {
        path: Routes.CURRENCIES,
        label: t('currencies'),
        icon: BiMoney,
        roles: [UserRoles.OWNER],
      },
      {
        path: Routes.PRODUCT_UNITS,
        label: t('productUnits'),
        icon: BiUnite,
        roles: [UserRoles.OWNER],
      },
    ],
    [t]
  )

  return (
    <ChakraDrawer
      id="router-drawer"
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
      finalFocusRef={btnRef}
      closeOnOverlayClick={isMobile}
      blockScrollOnMount={false}
      trapFocus={false}
    >
      <DrawerOverlay display={{ base: 'block', md: 'none' }} />

      <DrawerContent containerProps={{ style: { width: isMobile ? '100%' : '20rem' } }}>
        <DrawerCloseButton mt={1} display={{ base: 'block', md: 'none' }} />
        <DrawerHeader>{t('welcome')}</DrawerHeader>

        <DrawerBody>
          <VStack spacing={2} align="stretch" h="100%">
            {routes
              .filter(route => userHasAnyRole(route.roles))
              .map(route => (
                <Link key={route.path} to={route.path}>
                  <Button
                    w="100%"
                    justifyContent="space-between"
                    colorScheme={location.pathname === route.path ? 'teal' : 'gray'}
                    leftIcon={<route.icon size={22} />}
                    rightIcon={<MdOutlineChevronRight />}
                  >
                    <Text width="100%" textAlign="left" fontSize="sm">
                      {route.label}
                    </Text>
                  </Button>
                </Link>
              ))}
            <Center mt="auto">
              <Text color="gray.500" fontSize="sm">
                {t('version', { version: appConfig.appVersion })}
              </Text>
            </Center>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  )
}

export default Drawer
