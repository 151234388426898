import i18n from '../i18n'
import { englishStringTranslator, RJSFValidationError, TranslatableString } from '@rjsf/utils'

export function fixupFormStrings(stringToTranslate: TranslatableString, params?: string[]) {
  if (params) {
    return englishStringTranslator(stringToTranslate, params)
  }

  return i18n.t(`translatableStringsOfForm.${stringToTranslate}`)
}

export function fixupFormErrorStrings(errors: RJSFValidationError[]) {
  return errors.map(error => {
    if (error.name === 'required') {
      error.message = i18n.t('formErrors.required')
    }

    if (error.name === 'minLength') {
      error.message = i18n.t('formErrors.minLength', { minLength: error.params.limit })
    }

    if (error.name === 'format') {
      error.message = i18n.t('formErrors.format', { format: error.params.format })
    }

    if (error.name === 'maximum') {
      error.message = i18n.t('formErrors.maximum', {
        comparison: error.params.comparison,
        limit: error.params.limit,
      })
    }

    if (error.name === 'minimum') {
      error.message = i18n.t('formErrors.minimum', {
        comparison: error.params.comparison,
        limit: error.params.limit,
      })
    }

    if (error.name === 'type') {
      error.message = i18n.t('formErrors.type', {
        type: error.params.type,
      })
    }
    return error
  })
}

export default fixupFormStrings
