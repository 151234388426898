import { useTranslation } from 'react-i18next'
import { Box, Center, Heading, Spinner, Text, useToast } from '@chakra-ui/react'
import { Form, UiSchema } from '@rjsf/chakra-ui'
import schema from '../../utils/form-schema.util'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import validator from '@rjsf/validator-ajv8'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AccountApi } from '../../utils/api-service.util'
import { useParams } from 'react-router-dom'
import UserRoles from '../../enums/user-roles.enum'
import { userHasRole } from '../../utils/user-role.util'
import fixupFormStrings, { fixupFormErrorStrings } from '../../utils/fixup-form-strings.util'

const AccountsEditPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'accounts' })
  const toast = useToast()
  const [formData, setFormData] = React.useState<object | undefined>()
  const { id } = useParams()

  const accountQuery = useQuery({
    queryKey: [FormSchemaTypes.GetAccountQueryResult, id],
    queryFn: () => AccountApi.apiAccountGet(id ?? ''),
    enabled: !!id,
  })

  useEffect(() => {
    if (accountQuery.data?.data) {
      const { id, email, name, lastName, isEmailConfirmed, roles } = accountQuery.data.data
      setFormData({
        schema: {
          id,
          email,
          name,
          lastName,
          isEmailConfirmed,
          roles,
        },
      })
    }
  }, [accountQuery.data?.data])

  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      submitText: t('update'),
    },
    schema: {
      id: {
        'ui:title': t('id'),
        'ui:readonly': true,
      },
      email: {
        'ui:title': t('email'),
      },
      password: {
        'ui:title': t('password'),
        'ui:widget': 'password',
      },
      name: {
        'ui:title': t('name'),
      },
      lastName: {
        'ui:title': t('lastName'),
      },
      isEmailConfirmed: {
        'ui:title': t('isEmailConfirmed'),
      },
      roles: {
        'ui:title': t('roles'),
        'ui:widget': 'checkboxes',
        'ui:options': {
          enumOptions: [
            { label: t(`userRoles.${UserRoles.OWNER}`), value: UserRoles.OWNER },
            { label: t(`userRoles.${UserRoles.MANAGER}`), value: UserRoles.MANAGER },
            { label: t(`userRoles.${UserRoles.USER}`), value: UserRoles.USER },
          ],
          enumDisabled: [UserRoles.OWNER, userHasRole(UserRoles.OWNER) ? '' : UserRoles.MANAGER],
        },
      },
    },
  }

  const updateMutation = useMutation({
    mutationFn: AccountApi.apiAccountPatch,
    onSuccess: () => {
      toast({
        title: t('updateSuccess'),
        status: 'success',
      })
    },
    onError: () => {
      toast({
        title: t('updateFailed'),
        status: 'error',
      })
    },
  })

  if (accountQuery.isError) {
    return (
      <Center height="calc(100dvh - 10rem)">
        <Text color="red.500">{t('fetchFailed')}</Text>
      </Center>
    )
  }

  if (accountQuery.isFetching || !formData) {
    return (
      <Center height="calc(100dvh - 10rem)">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box width="100%" display="flex" mt={15} flexDirection="column" alignItems="center">
      <Heading mb={4}>{t('edit')}</Heading>
      <Box width="full" maxWidth="lg">
        <Form
          formData={formData}
          schema={schema('', FormSchemaTypes.UpdateAccountCommand)}
          onChange={data => setFormData(data.formData)}
          onSubmit={data => updateMutation.mutate(data.formData.schema)}
          validator={validator}
          uiSchema={uiSchema}
          showErrorList={false}
          noHtml5Validate
          focusOnFirstError
          translateString={fixupFormStrings}
          transformErrors={fixupFormErrorStrings}
        />
      </Box>
    </Box>
  )
}

export default AccountsEditPage
