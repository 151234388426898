import { createContext, useContext } from 'react'
import { AuthContextProps } from './auth.context.types'

export const AuthContext = createContext<AuthContextProps | undefined>(undefined)
export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}
