import React, { useState } from 'react'
import { WidgetProps } from '@rjsf/utils'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { CityApi } from '../../utils/api-service.util'
import { ListCitiesQuery, ListCitiesQuerySortFieldsEnum, SortDirectionEnum } from '@vennyx-org/erp-api-client'
import maxInt32 from '../../constants/integer.constant'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import SelectWidget from '../select-widget/select-widget.component'
import { userHasRole } from '../../utils/user-role.util'
import UserRoles from '../../enums/user-roles.enum'
import Routes from '../../enums/routes.enum'

const CitySelectWidget = ({ id, disabled, options, label, required, value = '', onChange }: WidgetProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'citySelectWidget' })
  const { cityIdOnChange } = options
  const queryClient = useQueryClient()
  const [query] = useState<ListCitiesQuery>({
    recordNavigationRequest: {
      page: 1,
      pageSize: maxInt32,
      sorting: [{ direction: SortDirectionEnum.ASCENDING, fieldName: ListCitiesQuerySortFieldsEnum.CODE }],
    },
  })

  const { data, isError, isFetching, isPending } = useQuery({
    queryKey: [FormSchemaTypes.ListCitiesQuery, query],
    queryFn: () => CityApi.apiCitiesPut(query),
  })

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    ;(cityIdOnChange as Function | undefined)?.(event.target.value)
    onChange(event.target.value)
  }

  const handleOnRefresh = async () => {
    await queryClient.invalidateQueries({ queryKey: [FormSchemaTypes.ListCitiesQuery] })
  }

  return (
    <SelectWidget
      data={data?.data.cities.map(x => ({ label: x.name, value: x.id }))}
      id={id}
      disabled={disabled}
      label={label}
      required={required}
      value={value}
      onChange={handleOnChange}
      isLoading={isPending}
      isError={isError}
      loadingText={t('loading')}
      errorText={t('loadingError')}
      placeholder={t('placeholder')}
      addButtonVisible={userHasRole(UserRoles.OWNER)}
      addButtonTo={Routes.CITIES_CREATE}
      editButtonVisible={userHasRole(UserRoles.OWNER)}
      editButtonTo={Routes.CITIES_EDIT.replace(':id', value)}
      onRefresh={handleOnRefresh}
      isRefreshing={isFetching}
    />
  )
}

export default CitySelectWidget
