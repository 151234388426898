import * as XLSX from 'xlsx'
import { TableLayoutProps } from '../components/data-table/data-table.component.types'
import moment from 'moment-timezone'

const getValueByPath = (row: any, path: string): any => {
  return path.split('.').reduce((prev, curr) => prev?.[curr], row)
}

const exportDataToExcel = ({ data, columns }: Pick<TableLayoutProps, 'data' | 'columns'>): void => {
  const formattedData = data.map(row => {
    let newRow = {}
    columns
      .filter(col => !col.hidden)
      .forEach(col => {
        ;(newRow as any)[col.title] = col.key.includes('.') ? getValueByPath(row, col.key) : (row as any)[col.key]
      })
    return newRow
  })

  const worksheet = XLSX.utils.json_to_sheet(formattedData)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Table')

  XLSX.writeFile(workbook, `table-data-${moment().format('YYYY-MM-DD')}.xlsx`)
}

export default exportDataToExcel
