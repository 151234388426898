import React, { FC, useState } from 'react'
import { Divider, useDisclosure, VStack } from '@chakra-ui/react'
import DetailsModal from './details-modal.component'
import TableLayout from './table-layout.component'
import ErrorMessage from './error-message.component'
import ActionButtons from './action-buttons.component'
import { DataTableProps } from './data-table.component.types'
import PaginationControl from './pagination-control.component'
import exportDataToExcel from '../../utils/table-export-excel.util'
import exportDataToPDF from '../../utils/table-export-pdf.util'

const DataTable: FC<DataTableProps> = ({
  data,
  isLoading,
  error,
  onRefresh,
  onPageChange,
  currentPage = 1,
  pageSize = 20,
  totalPageCount = 1,
  onRowDelete,
  onSort,
  columns,
  onRowEditClick,
  onRowConvertToOrderClick,
  onRowCompleteOrderClick,
  defaultSort,
  isRowDeleting,
  isOfferConverting,
  isOrderCompleting,
  onCreateClick,
  translationKeyPrefix,
  onPageSizeChange,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedRow, setSelectedRow] = useState<{ [key: string]: any } | null>(null)

  return (
    <VStack w="100%" spacing={2} align="stretch">
      <ActionButtons
        onRefresh={onRefresh}
        onCreateClick={onCreateClick}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        isRefreshing={isLoading}
        onExportExcel={() => exportDataToExcel({ data, columns })}
        onExportPdf={() => exportDataToPDF({ data, columns })}
      />
      <Divider />
      <TableLayout
        data={data}
        columns={columns}
        isLoading={isLoading}
        onRowClick={setSelectedRow}
        onSort={onSort}
        onRowDelete={onRowDelete}
        onRowDetailClick={onOpen}
        onRowEditClick={onRowEditClick}
        onRowConvertToOrderClick={onRowConvertToOrderClick}
        onRowCompleteOrderClick={onRowCompleteOrderClick}
        isRowDeleting={isRowDeleting}
        isOfferConverting={isOfferConverting}
        isOrderCompleting={isOrderCompleting}
        defaultSort={defaultSort}
      />
      <ErrorMessage error={error} isShown={!isLoading} />
      <PaginationControl
        currentPage={currentPage}
        totalPageCount={totalPageCount}
        onPageChange={onPageChange}
        pageSize={pageSize}
        isPaginationShown={totalPageCount > 1}
        isPaginationDisabled={isLoading}
      />
      <DetailsModal isOpen={isOpen} onClose={onClose} selectedRow={selectedRow} translationKeyPrefix={translationKeyPrefix} />
    </VStack>
  )
}

export default DataTable
