import { useTranslation } from 'react-i18next'
import { Box, Center, Heading, Spinner, useToast } from '@chakra-ui/react'
import { Form, UiSchema } from '@rjsf/chakra-ui'
import schema from '../../utils/form-schema.util'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import validator from '@rjsf/validator-ajv8'
import React, { useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { ProductApi } from '../../utils/api-service.util'
import { useParams } from 'react-router-dom'
import ProductUnitSelectWidget from '../../components/product-unit-select-widget/product-unit-select-widget.component'
import ProductCategorySelectWidget from '../../components/product-category-select-widget/product-category-select-widget.component'
import CurrencySelectWidget from '../../components/currency-select-widget/currency-select.widget.component'
import fixupFormStrings, { fixupFormErrorStrings } from '../../utils/fixup-form-strings.util'

const ProductEditPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'products' })
  const toast = useToast()
  const [formData, setFormData] = React.useState<object | undefined>()
  const { id } = useParams()

  const { data, isFetching } = useQuery({
    queryKey: [FormSchemaTypes.GetProductQueryResult, id],
    queryFn: () => ProductApi.apiProductGet(id ?? ''),
    enabled: !!id,
  })

  useEffect(() => {
    if (data?.data) {
      setFormData({
        schema: {
          id: data.data.id,
          code: data.data.code,
          name: data.data.name,
          unitId: data.data.productUnit?.id,
          categoryId: data.data.productCategory?.id,
          buyingPriceCurrencyId: data.data.buyingPriceCurrencyId,
          sellingPriceCurrencyId: data.data.sellingPriceCurrencyId,
          wholesaleVatRate: data.data.wholesaleVatRate,
          retailVatRate: data.data.retailVatRate,
          buyingPrice: data.data.buyingPrice,
          buyingPriceExcludingVat: data.data.buyingPriceExcludingVat,
          sellingPrice: data.data.sellingPrice,
          sellingPriceExcludingVat: data.data.sellingPriceExcludingVat,
        },
      })
    }
  }, [data?.data])

  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      submitText: t('update'),
    },
    schema: {
      id: {
        'ui:title': t('id'),
        'ui:readonly': true,
      },
      code: {
        'ui:title': t('code'),
      },
      name: {
        'ui:title': t('name'),
      },
      unitId: {
        'ui:title': t('unit'),
        'ui:widget': ProductUnitSelectWidget,
      },
      categoryId: {
        'ui:title': t('category'),
        'ui:widget': ProductCategorySelectWidget,
      },
      buyingPriceCurrencyId: {
        'ui:title': t('buyingPriceCurrencyId'),
        'ui:widget': CurrencySelectWidget,
      },
      sellingPriceCurrencyId: {
        'ui:title': t('sellingPriceCurrencyId'),
        'ui:widget': CurrencySelectWidget,
      },
      wholesaleVatRate: {
        'ui:title': t('wholesaleVatRate'),
      },
      retailVatRate: {
        'ui:title': t('retailVatRate'),
      },
      buyingPrice: {
        'ui:title': t('buyingPrice'),
      },
      buyingPriceExcludingVat: {
        'ui:title': t('buyingPriceExcludingVat'),
      },
      sellingPrice: {
        'ui:title': t('sellingPrice'),
      },
      sellingPriceExcludingVat: {
        'ui:title': t('sellingPriceExcludingVat'),
      },
    },
  }

  const updateMutation = useMutation({
    mutationFn: ProductApi.apiProductPatch,
    onSuccess: () => {
      toast({
        title: t('updateSuccess'),
        status: 'success',
      })
    },
    onError: () => {
      toast({
        title: t('updateFailed'),
        status: 'error',
      })
    },
  })

  if (!formData || isFetching) {
    return (
      <Center height="calc(100dvh - 10rem)">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box width="100%" display="flex" mt={15} flexDirection="column" alignItems="center">
      <Heading as="h1" size="xl" textAlign="center" mb={5}>
        {t('edit')}
      </Heading>
      <Box width="full" maxWidth="lg">
        <Form
          formData={formData}
          schema={schema('', FormSchemaTypes.UpdateProductCommand)}
          onChange={data => setFormData(data.formData)}
          onSubmit={data => updateMutation.mutate(data.formData.schema)}
          validator={validator}
          uiSchema={uiSchema}
          showErrorList={false}
          noHtml5Validate
          focusOnFirstError
          translateString={fixupFormStrings}
          transformErrors={fixupFormErrorStrings}
        />
      </Box>
    </Box>
  )
}

export default ProductEditPage
