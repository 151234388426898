import React from 'react'
import { Box } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import Drawer from '../drawer/drawer.component'
import { useDrawer } from '../../contexes/drawer/drawer.context'
import Navbar from '../navbar/navbar.component'

const Layout = () => {
  const drawer = useDrawer()

  return (
    <Box>
      <Drawer />
      <Box p={{ base: 4, md: 8 }} transition="margin 0.35s ease-in-out" marginLeft={{ md: drawer.isOpen ? '20rem' : 0, base: 0 }}>
        <Navbar />
        <Outlet />
      </Box>
    </Box>
  )
}

export default Layout
