import { useTranslation } from 'react-i18next'
import { Box, Heading, useToast } from '@chakra-ui/react'
import { Form } from '@rjsf/chakra-ui'
import schema from '../../utils/form-schema.util'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import validator from '@rjsf/validator-ajv8'
import React, { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { ProductApi } from '../../utils/api-service.util'
import CurrencySelectWidget from '../../components/currency-select-widget/currency-select.widget.component'
import ProductCategorySelectWidget from '../../components/product-category-select-widget/product-category-select-widget.component'
import ProductUnitSelectWidget from '../../components/product-unit-select-widget/product-unit-select-widget.component'
import RoutesEnum from '../../enums/routes.enum'
import { useNavigate } from 'react-router-dom'
import fixupFormStrings, { fixupFormErrorStrings } from '../../utils/fixup-form-strings.util'

const ProductsCreatePage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'products' })
  const toast = useToast()
  const navigate = useNavigate()

  const uiSchema = useMemo(
    () => ({
      'ui:submitButtonOptions': {
        props: {
          disabled: false,
        },
        submitText: t('submit'),
      },
      schema: {
        code: {
          'ui:title': t('code'),
        },
        name: {
          'ui:title': t('name'),
        },
        unitId: {
          'ui:title': t('unit'),
          'ui:widget': ProductUnitSelectWidget,
        },
        categoryId: {
          'ui:title': t('category'),
          'ui:widget': ProductCategorySelectWidget,
        },
        buyingPriceCurrencyId: {
          'ui:title': t('buyingPriceCurrencyId'),
          'ui:widget': CurrencySelectWidget,
        },
        sellingPriceCurrencyId: {
          'ui:title': t('sellingPriceCurrencyId'),
          'ui:widget': CurrencySelectWidget,
        },
        wholesaleVatRate: {
          'ui:title': t('wholesaleVatRate'),
        },
        retailVatRate: {
          'ui:title': t('retailVatRate'),
        },
        buyingPrice: {
          'ui:title': t('buyingPrice'),
        },
        buyingPriceExcludingVat: {
          'ui:title': t('buyingPriceExcludingVat'),
        },
        sellingPrice: {
          'ui:title': t('sellingPrice'),
        },
        sellingPriceExcludingVat: {
          'ui:title': t('sellingPriceExcludingVat'),
        },
      },
    }),
    [t]
  )

  const submitMutation = useMutation({
    mutationKey: [FormSchemaTypes.CreateProductCommand],
    mutationFn: ProductApi.apiProductPost,
    onSuccess: () => {
      toast({
        title: t('createSuccess'),
        status: 'success',
      })
      navigate(RoutesEnum.PRODUCTS)
    },
    onError: () => {
      toast({
        title: t('createFailed'),
        status: 'error',
      })
    },
  })

  return (
    <Box width="100%" display="flex" mt={15} flexDirection="column" alignItems="center">
      <Heading as="h1" size="xl" textAlign="center" mb={5}>
        {t('create')}
      </Heading>
      <Box width="full" maxWidth="lg">
        <Form
          schema={schema('', FormSchemaTypes.CreateProductCommand)}
          onSubmit={data => submitMutation.mutate(data.formData.schema)}
          validator={validator}
          uiSchema={uiSchema}
          showErrorList={false}
          noHtml5Validate
          focusOnFirstError
          translateString={fixupFormStrings}
          transformErrors={fixupFormErrorStrings}
        />
      </Box>
    </Box>
  )
}

export default ProductsCreatePage
