import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DataTable from '../../components/data-table/data-table.component'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { OrderApi } from '../../utils/api-service.util'
import { useNavigate } from 'react-router-dom'
import RoutesEnum from '../../enums/routes.enum'
import { Box, Heading, useToast } from '@chakra-ui/react'
import { ListOrderQuery, ListOrderQuerySortFieldsEnum, SortDirectionEnum } from '@vennyx-org/erp-api-client'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import moment from 'moment-timezone'
import { Column, SortDirection } from '../../components/data-table/data-table.component.types'

const OrdersPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'orders' })
  const navigate = useNavigate()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [query, setQuery] = useState<ListOrderQuery>({
    recordNavigationRequest: {
      page: 1,
      pageSize: 50,
      sorting: [{ direction: SortDirectionEnum.ASCENDING, fieldName: ListOrderQuerySortFieldsEnum.DELIVERY_DATE_TIME }],
    },
  })

  const { data, error, isFetching, refetch } = useQuery({
    queryKey: [FormSchemaTypes.ListOrderQuery, query],
    queryFn: () => OrderApi.apiOrdersPut(query),
    select: data => {
      let computedData = data
      if (data.data.orders) {
        computedData.data.orders = data.data.orders.map(order => {
          return {
            ...order,
            orderDateTime: moment(order.orderDateTime).format('YYYY-MM-DD HH:mm'),
            deliveryDateTime: moment(order.deliveryDateTime).format('YYYY-MM-DD HH:mm'),
          }
        })
      }

      return computedData
    },
  })

  const removeOrdersMutation = useMutation({
    mutationFn: OrderApi.apiOrderDelete,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FormSchemaTypes.ListOrderQuery] })
    },
  })

  const completeOrderMutation = useMutation({
    mutationFn: OrderApi.apiOrderCompleteOrderPatch,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FormSchemaTypes.ListOrderQuery] })
      toast({
        title: t('completeOrderSuccess'),
        status: 'success',
      })
    },
    onError: () => {
      toast({
        title: t('completeOrderFailed'),
        status: 'error',
      })
    },
  })

  const handlePageChange = (page: number) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        ...query.recordNavigationRequest,
        page,
      },
    })
  }

  const handlePageSizeChange = (pageSize: number) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        page: 1,
        pageSize,
      },
    })
  }

  const columns: Column[] = useMemo(
    () => [
      {
        key: 'category.name',
        title: t('categoryName'),
      },
      {
        key: 'tradingPartnerName',
        title: t('tradingPartnerName'),
      },
      {
        key: 'orderDateTime',
        title: t('orderDateTime'),
        sortable: true,
      },
      {
        key: 'deliveryDateTime',
        title: t('deliveryDateTime'),
        sortable: true,
      },
      {
        key: 'totalAmount',
        title: t('totalAmount'),
        sortable: true,
      },
    ],
    [t]
  )

  const handleSort = (column: string, direction: SortDirection) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        ...query.recordNavigationRequest,
        sorting: [
          {
            fieldName: column as ListOrderQuerySortFieldsEnum,
            direction: direction === SortDirection.ASCENDING ? SortDirectionEnum.ASCENDING : SortDirectionEnum.DESCENDING,
          },
        ],
      },
    })
  }

  return (
    <Box mt={4}>
      <Heading mb={4}>{t('title')}</Heading>
      <DataTable
        data={data?.data.orders || []}
        columns={columns}
        onRefresh={refetch}
        onRowDelete={row => removeOrdersMutation.mutate(row.id)}
        onRowEditClick={row => navigate(RoutesEnum.ORDERS_EDIT.replace(':id', row.id))}
        onRowCompleteOrderClick={row => completeOrderMutation.mutate(row.id)}
        onCreateClick={() => navigate(RoutesEnum.ORDERS_CREATE)}
        error={error?.message}
        isLoading={isFetching}
        currentPage={query.recordNavigationRequest?.page}
        pageSize={query.recordNavigationRequest?.pageSize}
        totalPageCount={data?.data.paginationData?.totalPages}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        isRowDeleting={removeOrdersMutation.isPending}
        isOrderCompleting={completeOrderMutation.isPending}
        translationKeyPrefix="orders"
        defaultSort={{ deliveryDateTime: SortDirection.ASCENDING }}
        onSort={handleSort}
      />
    </Box>
  )
}

export default OrdersPage
