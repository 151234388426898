import React, { FC, ReactElement } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { Center, Spinner } from '@chakra-ui/react'
import { useAuth } from '../../contexes/auth/auth.context'
import { AuthStatus } from '../../contexes/auth/auth.context.types'
import Routes from '../../enums/routes.enum'
import UserRoles from '../../enums/user-roles.enum'
import { userHasAnyRole } from '../../utils/user-role.util'

interface ProtectedRouteProps {
  children: ReactElement
  allowedRoles?: UserRoles[]
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, allowedRoles }) => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.loadingStatus === AuthStatus.UNAUTHENTICATED) {
    return <Navigate to={Routes.LOGIN} state={{ from: location }} replace />
  }

  if (auth.loadingStatus !== AuthStatus.AUTHENTICATED) {
    return (
      <Center height="calc(100dvh - 12rem)">
        <Spinner />
      </Center>
    )
  }

  if (allowedRoles && !userHasAnyRole(allowedRoles)) {
    return <Navigate to={Routes.RESTRICTED} replace />
  }

  return children
}

export default ProtectedRoute
