import AuthTokens from '../../declerations/auth-tokens.decleration'
import JWTToken from '../../declerations/jwt-token.decleration'

export enum AuthStatus {
  LOADING = 'LOADING',
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

interface AuthContextProps {
  roles: string[]
  loadingStatus: AuthStatus
  authTokens: AuthTokens | null
  decodedToken: JWTToken | null
  changeLoadingStatus: (status: AuthStatus) => void
  updateAuthStatus: (auth: string) => void
  logout: () => void
}

export type { AuthContextProps }
export default undefined
