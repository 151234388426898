import { DataRowProps } from './data-table.component.types'
import React, { FC } from 'react'
import { Button, HStack, IconButton, Popover, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Td, Tr } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { CgTrash } from 'react-icons/cg'
import { FaRegEdit } from 'react-icons/fa'
import { IoSearch } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'

const DataRow: FC<DataRowProps> = ({
  row,
  columns,
  onRowDelete,
  onRowDetailClick,
  onRowEditClick,
  onRowClick,
  isRowDeleting,
  onRowConvertToOrderClick,
  onRowCompleteOrderClick,
  isOfferConverting,
  isOrderCompleting,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'dataTable' })

  const location = useLocation()

  const handleEditClick = () => {
    onRowEditClick?.(row)
  }

  const handleDetailClick = () => {
    onRowClick(row)
    onRowDetailClick?.(row)
  }

  const handleConvertClick = () => {
    onRowConvertToOrderClick?.(row)
  }

  const handleCompleteOrderClick = () => {
    onRowCompleteOrderClick?.(row)
  }

  return (
    <Tr>
      {columns
        .filter(column => !column.hidden)
        .map(column => {
          let computedKey = row[column.key]
          if (column.key.includes('.') && row) {
            computedKey = column.key.split('.').reduce((prev, curr) => prev?.[curr], row)
          }
          if (typeof computedKey === 'boolean') {
            computedKey = computedKey ? t('yes') : t('no')
          }
          return (
            <Td key={column.key} maxWidth={250} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {computedKey}
            </Td>
          )
        })}
      <Td textAlign="right">
        <HStack spacing={1} justifyContent="flex-end">
          {location.pathname.includes('orders') && (
            <Button size="sm" colorScheme="teal" onClick={handleCompleteOrderClick} isDisabled={row.isCompleted} isLoading={!row.isCompleted && isOrderCompleting}>
              {row.isCompleted ? t('incomplete') : t('complete')}
            </Button>
          )}
          {location.pathname.includes('offers') && (
            <Button size="sm" colorScheme="teal" onClick={handleConvertClick} isLoading={isOfferConverting}>
              {t('convertToOrder')}
            </Button>
          )}
          <IconButton aria-label={t('details')} icon={<IoSearch size={18} />} variant="outline" colorScheme="teal" size="sm" borderRadius="md" onClick={handleDetailClick} />
          <IconButton aria-label={t('edit')} icon={<FaRegEdit size={16} />} variant="outline" colorScheme="blue" size="sm" borderRadius="md" onClick={handleEditClick} />
          <Popover>
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <IconButton aria-label={t('delete')} variant="outline" icon={<CgTrash size={18} />} colorScheme="red" size="sm" borderRadius="md" isLoading={isRowDeleting} />
                </PopoverTrigger>
                <PopoverContent textAlign="left">
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>{t('confirmDelete')}</PopoverHeader>
                  <PopoverFooter>
                    <Button size="sm" mr={2} onClick={onClose} variant="outline">
                      {t('cancel')}
                    </Button>
                    <Button size="sm" colorScheme="red" onClick={() => onRowDelete?.(row)} isLoading={isRowDeleting}>
                      {t('delete')}
                    </Button>
                  </PopoverFooter>
                </PopoverContent>
              </>
            )}
          </Popover>
        </HStack>
      </Td>
    </Tr>
  )
}

export default DataRow
