import { useTranslation } from 'react-i18next'
import CitySelectWidget from '../../components/city-select-widget/city-select-widget.component'
import { Box, Heading, useToast } from '@chakra-ui/react'
import { Form, UiSchema } from '@rjsf/chakra-ui'
import schema from '../../utils/form-schema.util'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import validator from '@rjsf/validator-ajv8'
import React from 'react'
import DistrictSelectWidget from '../../components/district-select-widget/district-select-widget.component'
import { useMutation } from '@tanstack/react-query'
import { TradingPartnerApi } from '../../utils/api-service.util'
import { useNavigate } from 'react-router-dom'
import RoutesEnum from '../../enums/routes.enum'
import fixupFormStrings, { fixupFormErrorStrings } from '../../utils/fixup-form-strings.util'

const TradingPartnersCreatePage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'tradingPartners' })
  const toast = useToast()
  const navigate = useNavigate()
  const [cityId, setCityId] = React.useState<string | undefined>()
  const [formData, setFormData] = React.useState({})

  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      submitText: t('submit'),
    },
    schema: {
      code: {
        'ui:title': t('code'),
      },
      name: {
        'ui:title': t('businessName'),
      },
      taxOffice: {
        'ui:title': t('taxOffice'),
      },
      taxOrTcNumber: {
        'ui:title': t('taxOrTcNumber'),
      },
      webAddress: {
        'ui:title': t('webAddress'),
      },
      emailAddress: {
        'ui:title': t('emailAddress'),
      },
      phone: {
        'ui:title': t('phone'),
      },
      gsm: {
        'ui:title': t('gsm'),
        'ui:options': {
          inputType: 'tel',
        },
      },
      postalCode: {
        'ui:title': t('postalCode'),
      },
      addressTitle: {
        'ui:title': t('addressTitle'),
      },
      address: {
        'ui:title': t('address'),
      },
      cityId: {
        'ui:title': t('city'),
        'ui:widget': CitySelectWidget,
        'ui:options': {
          cityIdOnChange: setCityId,
        },
      },
      districtId: {
        'ui:title': t('district'),
        'ui:widget': DistrictSelectWidget,
        'ui:options': {
          cityId: cityId,
        },
      },
    },
  }

  const submitMutation = useMutation({
    mutationKey: [FormSchemaTypes.CreateTradingPartnerCommand],
    mutationFn: TradingPartnerApi.apiTradingPartnerPost,
    onSuccess: () => {
      toast({
        title: t('createSuccess'),
        status: 'success',
      })
      setCityId(undefined)
      setFormData({})
      navigate(RoutesEnum.TRADING_PARTNERS)
    },
    onError: () => {
      toast({
        title: t('createFailed'),
        status: 'error',
      })
    },
  })

  return (
    <Box width="100%" display="flex" mt={15} flexDirection="column" alignItems="center">
      <Heading as="h1" size="lg" mb={4}>
        {t('create')}
      </Heading>
      <Box width="full" maxWidth="lg">
        <Form
          formData={formData}
          schema={schema('', FormSchemaTypes.CreateTradingPartnerCommand)}
          onChange={data => setFormData(data.formData)}
          onSubmit={data => submitMutation.mutate(data.formData.schema)}
          validator={validator}
          uiSchema={uiSchema}
          showErrorList={false}
          noHtml5Validate
          focusOnFirstError
          translateString={fixupFormStrings}
          transformErrors={fixupFormErrorStrings}
        />
      </Box>
    </Box>
  )
}

export default TradingPartnersCreatePage
