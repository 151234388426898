import React from 'react'
import { FormControl, HStack, IconButton, Select } from '@chakra-ui/react'
import WidgetFormLabel from '../widget-form-label/widget-form-label.component'
import { Link } from 'react-router-dom'
import { RiRefreshLine } from 'react-icons/ri'
import { AiOutlinePlus } from 'react-icons/ai'
import SelectWidgetProps from './select-widget.component.types'
import { FaRegEdit } from 'react-icons/fa'

const SelectWidget = ({
  id,
  disabled,
  label,
  required,
  value = '',
  onChange,
  isLoading,
  isError,
  errorText,
  loadingText,
  data,
  placeholder,
  addButtonTo,
  addButtonVisible,
  isRefreshing,
  onRefresh,
  editButtonVisible,
  editButtonTo,
}: SelectWidgetProps) => {
  if (isLoading || isError) {
    return (
      <FormControl id={id}>
        <WidgetFormLabel label={label} required={required} id={id} />
        <Select id={id} disabled>
          <option>{isLoading ? loadingText : errorText}</option>
        </Select>
      </FormControl>
    )
  }

  return (
    <FormControl id={id}>
      <WidgetFormLabel label={label} required={required} id={id} />
      <HStack>
        <Select id={id} value={value} disabled={disabled} onChange={onChange}>
          <option disabled value="">
            {placeholder}
          </option>
          {data?.map(x => (
            <option key={x.value} value={x.value}>
              {x.label}
            </option>
          ))}
        </Select>
        {editButtonVisible && editButtonTo && (
          <Link to={editButtonTo} target="_blank">
            <IconButton aria-label="edit" variant="outline" icon={<FaRegEdit />} colorScheme="yellow" isDisabled={value === ''} as="span" />
          </Link>
        )}
        {addButtonVisible && addButtonTo && (
          <Link to={addButtonTo} target="_blank">
            <IconButton aria-label="add" variant="outline" icon={<AiOutlinePlus />} colorScheme="green" as="span" />
          </Link>
        )}
        <IconButton aria-label="refresh" variant="outline" icon={<RiRefreshLine />} colorScheme="blue" onClick={onRefresh} isLoading={isRefreshing} />
      </HStack>
    </FormControl>
  )
}

export default SelectWidget
