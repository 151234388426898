import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DataTable from '../../components/data-table/data-table.component'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AccountApi } from '../../utils/api-service.util'
import { useNavigate } from 'react-router-dom'
import RoutesEnum from '../../enums/routes.enum'
import { Box, Heading } from '@chakra-ui/react'
import { ListAccountsQuery, ListAccountsQuerySortFieldsEnum, SortDirectionEnum } from '@vennyx-org/erp-api-client'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import moment from 'moment-timezone'
import { SortDirection } from '../../components/data-table/data-table.component.types'

const AccountsPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'accounts' })
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [query, setQuery] = useState<ListAccountsQuery>({
    recordNavigationRequest: {
      page: 1,
      pageSize: 50,
      sorting: [
        {
          direction: SortDirectionEnum.ASCENDING,
          fieldName: ListAccountsQuerySortFieldsEnum.NAME,
        },
      ],
    },
  })

  const { data, error, isFetching, refetch } = useQuery({
    queryKey: [FormSchemaTypes.ListAccountsQuery, query],
    queryFn: () => AccountApi.apiAccountsPut(query),
  })

  const computedData = useMemo(() => {
    let computed = data
    if (computed?.data.accounts) {
      computed.data.accounts = computed.data.accounts.map(account => {
        return {
          ...account,
          createdDateTime: account.createdDateTime ? moment(account.createdDateTime).format('YYYY-MM-DD HH:mm') : '',
          accountLockedFromDateTime: account.accountLockedFromDateTime ? moment(account.accountLockedFromDateTime).format('YYYY-MM-DD HH:mm') : '',
          accountLockedToDateTime: account.accountLockedToDateTime ? moment(account.accountLockedToDateTime).format('YYYY-MM-DD HH:mm') : '',
          emailConfirmDateTime: account.emailConfirmDateTime ? moment(account.emailConfirmDateTime).format('YYYY-MM-DD HH:mm') : '',
          roles: account.roles?.map(role => t('userRoles.' + role)),
        }
      })
    }
    return computed
  }, [data, t])

  const removeAccountMutation = useMutation({
    mutationFn: AccountApi.apiAccountDelete,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FormSchemaTypes.ListAccountsQuery] })
    },
  })

  const handlePageChange = (page: number) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        ...query.recordNavigationRequest,
        page,
      },
    })
  }

  const handlePageSizeChange = (pageSize: number) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        page: 1,
        pageSize,
      },
    })
  }

  const columns = useMemo(
    () => [
      {
        key: 'name',
        title: t('name'),
        sortable: true,
      },
      {
        key: 'lastName',
        title: t('lastName'),
        sortable: true,
      },
      {
        key: 'email',
        title: t('email'),
        sortable: true,
      },
    ],
    [t]
  )

  const handleSort = (column: string, direction: SortDirection) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        ...query.recordNavigationRequest,
        sorting: [
          {
            fieldName: column as ListAccountsQuerySortFieldsEnum,
            direction: direction === SortDirection.ASCENDING ? SortDirectionEnum.ASCENDING : SortDirectionEnum.DESCENDING,
          },
        ],
      },
    })
  }

  return (
    <Box mt={4}>
      <Heading mb={4}>{t('title')}</Heading>
      <DataTable
        data={computedData?.data.accounts || []}
        columns={columns}
        onRefresh={refetch}
        onRowDelete={row => removeAccountMutation.mutate(row.id)}
        onRowEditClick={row => navigate(RoutesEnum.ACCOUNTS_EDIT.replace(':id', row.id))}
        onCreateClick={() => navigate(RoutesEnum.ACCOUNTS_CREATE)}
        error={error?.message}
        isLoading={isFetching}
        currentPage={query.recordNavigationRequest?.page}
        pageSize={query.recordNavigationRequest?.pageSize}
        totalPageCount={data?.data.paginationData?.totalPages}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        isRowDeleting={removeAccountMutation.isPending}
        translationKeyPrefix="accounts"
        defaultSort={{ name: SortDirection.ASCENDING }}
        onSort={handleSort}
      />
    </Box>
  )
}

export default AccountsPage
