enum FormSchemaTypes {
  AccountListItem = 'AccountListItem',
  ChangePasswordCommand = 'ChangePasswordCommand',
  CityInDistrict = 'CityInDistrict',
  CityInTradingPartner = 'CityInTradingPartner',
  CityListItem = 'CityListItem',
  ConvertToOrderCommand = 'ConvertToOrderCommand',
  CreateAccountCommand = 'CreateAccountCommand',
  CreateAccountCommandResult = 'CreateAccountCommandResult',
  CreateCityCommand = 'CreateCityCommand',
  CreateCityCommandResult = 'CreateCityCommandResult',
  CreateCurrencyCommand = 'CreateCurrencyCommand',
  CreateCurrencyCommandResult = 'CreateCurrencyCommandResult',
  CreateDistrictCommand = 'CreateDistrictCommand',
  CreateDistrictCommandResult = 'CreateDistrictCommandResult',
  CreateOfferCategoryCommand = 'CreateOfferCategoryCommand',
  CreateOfferCategoryCommandResult = 'CreateOfferCategoryCommandResult',
  CreateOfferCommand = 'CreateOfferCommand',
  CreateOfferCommandResult = 'CreateOfferCommandResult',
  CreateOfferDetailListItem = 'CreateOfferDetailListItem',
  CreateOrderCategoryCommand = 'CreateOrderCategoryCommand',
  CreateOrderCategoryCommandResult = 'CreateOrderCategoryCommandResult',
  CreateOrderCommand = 'CreateOrderCommand',
  CreateOrderCommandResult = 'CreateOrderCommandResult',
  CreateOrderDetailListItem = 'CreateOrderDetailListItem',
  CreateProductCategoryCommand = 'CreateProductCategoryCommand',
  CreateProductCategoryCommandResult = 'CreateProductCategoryCommandResult',
  CreateProductCommand = 'CreateProductCommand',
  CreateProductCommandResult = 'CreateProductCommandResult',
  CreateProductUnitCommand = 'CreateProductUnitCommand',
  CreateProductUnitCommandResult = 'CreateProductUnitCommandResult',
  CreateTradingPartnerCommand = 'CreateTradingPartnerCommand',
  CreateTradingPartnerCommandResult = 'CreateTradingPartnerCommandResult',
  CurrencyInOffer = 'CurrencyInOffer',
  CurrencyInOrder = 'CurrencyInOrder',
  CurrencyListItem = 'CurrencyListItem',
  DistrictInTradingPartner = 'DistrictInTradingPartner',
  DistrictListItem = 'DistrictListItem',
  ForgotPasswordCommand = 'ForgotPasswordCommand',
  GetAccountQueryResult = 'GetAccountQueryResult',
  GetCityQueryResult = 'GetCityQueryResult',
  GetCurrencyQueryResult = 'GetCurrencyQueryResult',
  GetDistrictQueryResult = 'GetDistrictQueryResult',
  GetOfferCategoryQueryResult = 'GetOfferCategoryQueryResult',
  GetOfferDetailListItem = 'GetOfferDetailListItem',
  GetOfferQueryResult = 'GetOfferQueryResult',
  GetOrderCategoryQueryResult = 'GetOrderCategoryQueryResult',
  GetOrderDetailListItem = 'GetOrderDetailListItem',
  GetOrderQueryResult = 'GetOrderQueryResult',
  GetProductCategoryQueryResult = 'GetProductCategoryQueryResult',
  GetProductQueryResult = 'GetProductQueryResult',
  GetProductUnitQueryResult = 'GetProductUnitQueryResult',
  GetTradingPartnerQueryResult = 'GetTradingPartnerQueryResult',
  ListAccountsQuery = 'ListAccountsQuery',
  ListAccountsQueryResult = 'ListAccountsQueryResult',
  ListAccountsQuerySortFieldsEnum = 'ListAccountsQuerySortFieldsEnum',
  ListAccountsQuerySortFieldsEnumRecordNavigationRequest = 'ListAccountsQuerySortFieldsEnumRecordNavigationRequest',
  ListAccountsQuerySortFieldsEnumSort = 'ListAccountsQuerySortFieldsEnumSort',
  ListCitiesQuery = 'ListCitiesQuery',
  ListCitiesQueryResult = 'ListCitiesQueryResult',
  ListCitiesQuerySortFieldsEnum = 'ListCitiesQuerySortFieldsEnum',
  ListCitiesQuerySortFieldsEnumRecordNavigationRequest = 'ListCitiesQuerySortFieldsEnumRecordNavigationRequest',
  ListCitiesQuerySortFieldsEnumSort = 'ListCitiesQuerySortFieldsEnumSort',
  ListCurrenciesQuery = 'ListCurrenciesQuery',
  ListCurrenciesQueryResult = 'ListCurrenciesQueryResult',
  ListCurrenciesQuerySortFieldsEnum = 'ListCurrenciesQuerySortFieldsEnum',
  ListCurrenciesQuerySortFieldsEnumRecordNavigationRequest = 'ListCurrenciesQuerySortFieldsEnumRecordNavigationRequest',
  ListCurrenciesQuerySortFieldsEnumSort = 'ListCurrenciesQuerySortFieldsEnumSort',
  ListDistrictsQuery = 'ListDistrictsQuery',
  ListDistrictsQueryResult = 'ListDistrictsQueryResult',
  ListDistrictsQuerySortFieldsEnum = 'ListDistrictsQuerySortFieldsEnum',
  ListDistrictsQuerySortFieldsEnumRecordNavigationRequest = 'ListDistrictsQuerySortFieldsEnumRecordNavigationRequest',
  ListDistrictsQuerySortFieldsEnumSort = 'ListDistrictsQuerySortFieldsEnumSort',
  ListOfferCategoriesQuery = 'ListOfferCategoriesQuery',
  ListOfferCategoriesQueryResult = 'ListOfferCategoriesQueryResult',
  ListOfferCategoriesQuerySortFieldsEnum = 'ListOfferCategoriesQuerySortFieldsEnum',
  ListOfferCategoriesQuerySortFieldsEnumRecordNavigationRequest = 'ListOfferCategoriesQuerySortFieldsEnumRecordNavigationRequest',
  ListOfferCategoriesQuerySortFieldsEnumSort = 'ListOfferCategoriesQuerySortFieldsEnumSort',
  ListOfferQuery = 'ListOfferQuery',
  ListOfferQueryResult = 'ListOfferQueryResult',
  ListOfferQuerySortFieldsEnum = 'ListOfferQuerySortFieldsEnum',
  ListOfferQuerySortFieldsEnumRecordNavigationRequest = 'ListOfferQuerySortFieldsEnumRecordNavigationRequest',
  ListOfferQuerySortFieldsEnumSort = 'ListOfferQuerySortFieldsEnumSort',
  ListOrderCategoriesQuery = 'ListOrderCategoriesQuery',
  ListOrderCategoriesQueryResult = 'ListOrderCategoriesQueryResult',
  ListOrderCategoriesQuerySortFieldsEnum = 'ListOrderCategoriesQuerySortFieldsEnum',
  ListOrderCategoriesQuerySortFieldsEnumRecordNavigationRequest = 'ListOrderCategoriesQuerySortFieldsEnumRecordNavigationRequest',
  ListOrderCategoriesQuerySortFieldsEnumSort = 'ListOrderCategoriesQuerySortFieldsEnumSort',
  ListOrderQuery = 'ListOrderQuery',
  ListOrderQueryResult = 'ListOrderQueryResult',
  ListOrderQuerySortFieldsEnum = 'ListOrderQuerySortFieldsEnum',
  ListOrderQuerySortFieldsEnumRecordNavigationRequest = 'ListOrderQuerySortFieldsEnumRecordNavigationRequest',
  ListOrderQuerySortFieldsEnumSort = 'ListOrderQuerySortFieldsEnumSort',
  ListProductCategoriesQuery = 'ListProductCategoriesQuery',
  ListProductCategoriesQueryResult = 'ListProductCategoriesQueryResult',
  ListProductCategoriesQuerySortFieldsEnum = 'ListProductCategoriesQuerySortFieldsEnum',
  ListProductCategoriesQuerySortFieldsEnumRecordNavigationRequest = 'ListProductCategoriesQuerySortFieldsEnumRecordNavigationRequest',
  ListProductCategoriesQuerySortFieldsEnumSort = 'ListProductCategoriesQuerySortFieldsEnumSort',
  ListProductUnitsQuery = 'ListProductUnitsQuery',
  ListProductUnitsQueryResult = 'ListProductUnitsQueryResult',
  ListProductUnitsQuerySortFieldsEnum = 'ListProductUnitsQuerySortFieldsEnum',
  ListProductUnitsQuerySortFieldsEnumRecordNavigationRequest = 'ListProductUnitsQuerySortFieldsEnumRecordNavigationRequest',
  ListProductUnitsQuerySortFieldsEnumSort = 'ListProductUnitsQuerySortFieldsEnumSort',
  ListProductsQuery = 'ListProductsQuery',
  ListProductsQueryResult = 'ListProductsQueryResult',
  ListProductsQuerySortFieldsEnum = 'ListProductsQuerySortFieldsEnum',
  ListProductsQuerySortFieldsEnumRecordNavigationRequest = 'ListProductsQuerySortFieldsEnumRecordNavigationRequest',
  ListProductsQuerySortFieldsEnumSort = 'ListProductsQuerySortFieldsEnumSort',
  ListTradingPartnersQuery = 'ListTradingPartnersQuery',
  ListTradingPartnersQueryResult = 'ListTradingPartnersQueryResult',
  ListTradingPartnersQuerySortFieldsEnum = 'ListTradingPartnersQuerySortFieldsEnum',
  ListTradingPartnersQuerySortFieldsEnumRecordNavigationRequest = 'ListTradingPartnersQuerySortFieldsEnumRecordNavigationRequest',
  ListTradingPartnersQuerySortFieldsEnumSort = 'ListTradingPartnersQuerySortFieldsEnumSort',
  LoginCommand = 'LoginCommand',
  LoginCommandResult = 'LoginCommandResult',
  OfferCategoryInOffer = 'OfferCategoryInOffer',
  OfferCategoryListItem = 'OfferCategoryListItem',
  OfferListItem = 'OfferListItem',
  OrderCategoryInOrder = 'OrderCategoryInOrder',
  OrderCategoryListItem = 'OrderCategoryListItem',
  OrderListItem = 'OrderListItem',
  PaginationData = 'PaginationData',
  ProductCategoryInProduct = 'ProductCategoryInProduct',
  ProductCategoryListItem = 'ProductCategoryListItem',
  ProductInOfferDetails = 'ProductInOfferDetails',
  ProductInOrderDetails = 'ProductInOrderDetails',
  ProductListItem = 'ProductListItem',
  ProductUnitInOfferDetails = 'ProductUnitInOfferDetails',
  ProductUnitInOrderDetails = 'ProductUnitInOrderDetails',
  ProductUnitInProduct = 'ProductUnitInProduct',
  ProductUnitListItem = 'ProductUnitListItem',
  RefreshCommand = 'RefreshCommand',
  RefreshCommandResult = 'RefreshCommandResult',
  SortDirectionEnum = 'SortDirectionEnum',
  TradingPartnerInOffer = 'TradingPartnerInOffer',
  TradingPartnerInOrder = 'TradingPartnerInOrder',
  TradingPartnerListItem = 'TradingPartnerListItem',
  Unit = 'Unit',
  UpdateAccountCommand = 'UpdateAccountCommand',
  UpdateCityCommand = 'UpdateCityCommand',
  UpdateCurrencyCommand = 'UpdateCurrencyCommand',
  UpdateDistrictCommand = 'UpdateDistrictCommand',
  UpdateOfferCategoryCommand = 'UpdateOfferCategoryCommand',
  UpdateOfferCommand = 'UpdateOfferCommand',
  UpdateOfferDetailListItem = 'UpdateOfferDetailListItem',
  UpdateOrderCategoryCommand = 'UpdateOrderCategoryCommand',
  UpdateOrderCommand = 'UpdateOrderCommand',
  UpdateOrderDetailListItem = 'UpdateOrderDetailListItem',
  UpdateProductCategoryCommand = 'UpdateProductCategoryCommand',
  UpdateProductCommand = 'UpdateProductCommand',
  UpdateProductUnitCommand = 'UpdateProductUnitCommand',
  UpdateTradingPartnerCommand = 'UpdateTradingPartnerCommand',
  VerifyPasswordResetCommand = 'VerifyPasswordResetCommand',
}
export default FormSchemaTypes
