import { RJSFSchema } from '@rjsf/utils'
import { formSchema } from '../constants/form-schema.constant'
import FormSchemaTypes from '../enums/form-schema-types.enum'

function schema(title: string, type: FormSchemaTypes): RJSFSchema {
  return {
    ...formSchema,
    properties: {
      schema: {
        title,
        type: 'object',
        $ref: `#/definitions/${type}`,
      },
    },
  }
}

export default schema
