import React, { FC } from 'react'
import { Skeleton, Td, Tr } from '@chakra-ui/react'
import { SkeletonRowsProps } from './data-table.component.types'

const SkeletonRows: FC<SkeletonRowsProps> = ({ rowCount, columnCount }) => (
  <>
    {Array.from({ length: rowCount }).map((_, rowIndex) => (
      <Tr key={rowIndex}>
        {Array.from({ length: columnCount }).map((_, columnIndex) => (
          <Td key={columnIndex}>
            <Skeleton height="1rem" my="0.5rem" />
          </Td>
        ))}
      </Tr>
    ))}
  </>
)

export default SkeletonRows
