import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Heading, Stack, Text, VStack } from '@chakra-ui/react'
import { useAuth } from '../../contexes/auth/auth.context'
import Routes from '../../enums/routes.enum'
import { useTranslation } from 'react-i18next'
import { FiLogOut } from 'react-icons/fi'
import { TbArrowBackUp } from 'react-icons/tb'
import { MdOutlineHome } from 'react-icons/md'

const RestrictedPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'restricted' })
  const auth = useAuth()
  const navigate = useNavigate()

  const handleLogout = () => {
    auth.logout()
    navigate(Routes.LOGIN)
  }

  return (
    <Box py={{ base: '12', md: '24' }} px={{ base: '4', md: '8' }}>
      <Stack spacing="16" align="center">
        <VStack spacing="4" align="center">
          <Heading textAlign="center">{t('title')}</Heading>
          <Text textAlign="center">{t('description')}</Text>
        </VStack>
        <Box>
          <Stack spacing="8" direction={{ base: 'column', md: 'row' }} align={{ base: 'strech', md: 'center' }}>
            <Button leftIcon={<TbArrowBackUp />} colorScheme="gray" variant="outline" onClick={() => navigate(-1)}>
              {t('back')}
            </Button>
            <Button leftIcon={<MdOutlineHome size={20} />} colorScheme="teal" variant="outline" onClick={() => navigate(Routes.HOME)}>
              {t('home')}
            </Button>
            <Button leftIcon={<FiLogOut />} colorScheme="red" variant="outline" onClick={handleLogout}>
              {t('logout')}
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}

export default RestrictedPage
