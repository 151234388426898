import React, { useState } from 'react'
import { WidgetProps } from '@rjsf/utils'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { DistrictApi } from '../../utils/api-service.util'
import { ListDistrictsQuery, ListDistrictsQuerySortFieldsEnum, SortDirectionEnum } from '@vennyx-org/erp-api-client'
import maxInt32 from '../../constants/integer.constant'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import Routes from '../../enums/routes.enum'
import { userHasRole } from '../../utils/user-role.util'
import UserRoles from '../../enums/user-roles.enum'
import SelectWidget from '../select-widget/select-widget.component'

const DistrictSelectWidget = ({ id, onChange, disabled, label, required, value = '', options }: WidgetProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'districtSelectWidget' })
  const cityId = options?.cityId as string | undefined
  const queryClient = useQueryClient()
  const [query] = useState<ListDistrictsQuery>({
    recordNavigationRequest: {
      page: 1,
      pageSize: maxInt32,
      sorting: [{ direction: SortDirectionEnum.ASCENDING, fieldName: ListDistrictsQuerySortFieldsEnum.NAME }],
    },
  })

  const { data, isError, isFetching, isPending } = useQuery({
    queryKey: [FormSchemaTypes.ListDistrictsQuery, cityId, query],
    queryFn: () => DistrictApi.apiDistrictsPut({ ...query, cityId }),
    enabled: !!cityId,
  })

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value)
  }

  const handleOnRefresh = async () => {
    await queryClient.invalidateQueries({ queryKey: [FormSchemaTypes.ListDistrictsQuery] })
  }

  return (
    <SelectWidget
      data={data?.data.districts.map(x => ({ label: x.name, value: x.id }))}
      id={id}
      disabled={disabled || !cityId}
      label={label}
      required={required}
      value={value}
      onChange={handleOnChange}
      isLoading={isPending && isFetching}
      isError={isError}
      loadingText={t('loading')}
      errorText={t('loadingError')}
      placeholder={t('placeholder')}
      addButtonVisible={userHasRole(UserRoles.OWNER)}
      addButtonTo={Routes.DISTRICTS_CREATE}
      editButtonVisible={userHasRole(UserRoles.OWNER)}
      editButtonTo={Routes.DISTRICTS_EDIT.replace(':id', value)}
      onRefresh={handleOnRefresh}
      isRefreshing={isFetching}
    />
  )
}

export default DistrictSelectWidget
