import { RJSFSchema } from '@rjsf/utils'

export const formSchema: RJSFSchema = {
  type: 'object',
  definitions: {
    AccountListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        email: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        lastName: {
          minLength: 1,
          type: 'string',
        },
        createdDateTime: {
          type: 'string',
          format: 'date-time',
        },
        fullName: {
          minLength: 1,
          type: 'string',
        },
        emailConfirmDateTime: {
          type: ['string', 'null'],
          format: 'date-time',
        },
        isEmailConfirmed: {
          type: 'boolean',
        },
        accountLockedFromDateTime: {
          type: ['string', 'null'],
          format: 'date-time',
        },
        accountLockedToDateTime: {
          type: ['string', 'null'],
          format: 'date-time',
        },
        roles: {
          type: ['array', 'null'],
          items: {
            type: 'string',
          },
        },
      },
      required: ['createdDateTime', 'email', 'fullName', 'id', 'isEmailConfirmed', 'lastName', 'name'],
      additionalProperties: false,
    },
    ChangePasswordCommand: {
      type: 'object',
      properties: {
        currentPassword: {
          minLength: 1,
          type: 'string',
        },
        newPassword: {
          minLength: 6,
          type: 'string',
        },
      },
      required: ['currentPassword', 'newPassword'],
      additionalProperties: false,
    },
    CityInDistrict: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    CityInTradingPartner: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    CityListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    ConvertToOrderCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        orderCategoryId: {
          type: ['string', 'null'],
          format: 'uuid',
        },
        deliveryDateTime: {
          type: ['string', 'null'],
          format: 'date-time',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateAccountCommand: {
      type: 'object',
      properties: {
        email: {
          maxLength: 256,
          minLength: 1,
          type: 'string',
          format: 'email',
        },
        password: {
          maxLength: 128,
          minLength: 6,
          type: 'string',
        },
        name: {
          maxLength: 70,
          minLength: 2,
          type: 'string',
        },
        lastName: {
          maxLength: 70,
          minLength: 2,
          type: 'string',
        },
        isEmailConfirmed: {
          type: 'boolean',
        },
        roles: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
      required: ['email', 'isEmailConfirmed', 'lastName', 'name', 'password', 'roles'],
      additionalProperties: false,
    },
    CreateAccountCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateCityCommand: {
      type: 'object',
      properties: {
        code: {
          maxLength: 2,
          minLength: 2,
          type: 'string',
        },
        name: {
          maxLength: 50,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'name'],
      additionalProperties: false,
    },
    CreateCityCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateCurrencyCommand: {
      type: 'object',
      properties: {
        code: {
          maxLength: 3,
          minLength: 3,
          type: 'string',
        },
        name: {
          maxLength: 60,
          minLength: 1,
          type: 'string',
        },
        symbol: {
          maxLength: 1,
          type: ['string', 'null'],
        },
      },
      required: ['code', 'name'],
      additionalProperties: false,
    },
    CreateCurrencyCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateDistrictCommand: {
      type: 'object',
      properties: {
        cityId: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          maxLength: 60,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['cityId', 'name'],
      additionalProperties: false,
    },
    CreateDistrictCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateOfferCategoryCommand: {
      type: 'object',
      properties: {
        name: {
          maxLength: 50,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['name'],
      additionalProperties: false,
    },
    CreateOfferCategoryCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateOfferCommand: {
      type: 'object',
      properties: {
        categoryId: {
          type: ['string', 'null'],
          format: 'uuid',
        },
        tradingPartnerId: {
          type: 'string',
          format: 'uuid',
        },
        offerDateTime: {
          type: 'string',
          format: 'date-time',
        },
        currencyId: {
          type: 'string',
          format: 'uuid',
        },
        description: {
          maxLength: 250,
          type: ['string', 'null'],
        },
        details: {
          type: 'array',
          items: {
            $ref: '#/definitions/CreateOfferDetailListItem',
          },
        },
      },
      required: ['currencyId', 'details', 'offerDateTime', 'tradingPartnerId'],
      additionalProperties: false,
    },
    CreateOfferCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateOfferDetailListItem: {
      type: 'object',
      properties: {
        productId: {
          type: 'string',
          format: 'uuid',
        },
        productCode: {
          maxLength: 25,
          minLength: 1,
          type: 'string',
        },
        productName: {
          maxLength: 150,
          minLength: 1,
          type: 'string',
        },
        quantity: {
          minimum: 1,
          type: 'number',
          format: 'double',
        },
        productUnitId: {
          type: 'string',
          format: 'uuid',
        },
        price: {
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        discountRate: {
          maximum: 100,
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        vatRate: {
          maximum: 100,
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        description: {
          maxLength: 250,
          type: ['string', 'null'],
        },
      },
      required: ['discountRate', 'price', 'productCode', 'productId', 'productName', 'productUnitId', 'quantity', 'vatRate'],
      additionalProperties: false,
    },
    CreateOrderCategoryCommand: {
      type: 'object',
      properties: {
        name: {
          maxLength: 50,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['name'],
      additionalProperties: false,
    },
    CreateOrderCategoryCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateOrderCommand: {
      type: 'object',
      properties: {
        categoryId: {
          type: ['string', 'null'],
          format: 'uuid',
        },
        tradingPartnerId: {
          type: 'string',
          format: 'uuid',
        },
        orderDateTime: {
          type: 'string',
          format: 'date-time',
        },
        deliveryDateTime: {
          type: 'string',
          format: 'date-time',
        },
        currencyId: {
          type: 'string',
          format: 'uuid',
        },
        description: {
          maxLength: 250,
          type: ['string', 'null'],
        },
        details: {
          type: 'array',
          items: {
            $ref: '#/definitions/CreateOrderDetailListItem',
          },
        },
      },
      required: ['currencyId', 'deliveryDateTime', 'details', 'orderDateTime', 'tradingPartnerId'],
      additionalProperties: false,
    },
    CreateOrderCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateOrderDetailListItem: {
      type: 'object',
      properties: {
        productId: {
          type: 'string',
          format: 'uuid',
        },
        productCode: {
          maxLength: 25,
          minLength: 1,
          type: 'string',
        },
        productName: {
          maxLength: 150,
          minLength: 1,
          type: 'string',
        },
        quantity: {
          minimum: 1,
          type: 'number',
          format: 'double',
        },
        productUnitId: {
          type: 'string',
          format: 'uuid',
        },
        price: {
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        discountRate: {
          maximum: 100,
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        vatRate: {
          maximum: 100,
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        description: {
          maxLength: 250,
          type: ['string', 'null'],
        },
      },
      required: ['discountRate', 'price', 'productCode', 'productId', 'productName', 'productUnitId', 'quantity', 'vatRate'],
      additionalProperties: false,
    },
    CreateProductCategoryCommand: {
      type: 'object',
      properties: {
        name: {
          maxLength: 60,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['name'],
      additionalProperties: false,
    },
    CreateProductCategoryCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateProductCommand: {
      type: 'object',
      properties: {
        code: {
          maxLength: 25,
          minLength: 1,
          type: 'string',
        },
        name: {
          maxLength: 150,
          minLength: 1,
          type: 'string',
        },
        unitId: {
          type: 'string',
          format: 'uuid',
        },
        categoryId: {
          type: 'string',
          format: 'uuid',
        },
        buyingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        sellingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        wholesaleVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        retailVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
        sellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        sellingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
      },
      required: ['buyingPriceCurrencyId', 'categoryId', 'code', 'name', 'sellingPriceCurrencyId', 'unitId'],
      additionalProperties: false,
    },
    CreateProductCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateProductUnitCommand: {
      type: 'object',
      properties: {
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          maxLength: 50,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'name'],
      additionalProperties: false,
    },
    CreateProductUnitCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CreateTradingPartnerCommand: {
      type: 'object',
      properties: {
        name: {
          maxLength: 200,
          minLength: 1,
          type: 'string',
        },
        taxOffice: {
          maxLength: 50,
          type: ['string', 'null'],
        },
        taxOrTcNumber: {
          maxLength: 11,
          minLength: 10,
          pattern: '^([1-9]{1}[0-9]{9}[02468]{1}|[0-9]{10})$',
          type: ['string', 'null'],
        },
        webAddress: {
          maxLength: 255,
          type: ['string', 'null'],
        },
        emailAddress: {
          maxLength: 150,
          type: ['string', 'null'],
          format: 'email',
        },
        phone: {
          maxLength: 10,
          minLength: 7,
          pattern: '^([1-9]{1}[0-9]{9}|[1-9]{1}[0-9]{6})$',
          type: ['string', 'null'],
        },
        gsm: {
          maxLength: 10,
          minLength: 10,
          pattern: '^([1-9]{1}[0-9]{9})$',
          type: ['string', 'null'],
        },
        postalCode: {
          maxLength: 10,
          type: ['string', 'null'],
        },
        addressTitle: {
          maxLength: 50,
          type: ['string', 'null'],
        },
        address: {
          maxLength: 255,
          type: ['string', 'null'],
        },
        cityId: {
          type: ['string', 'null'],
          format: 'uuid',
        },
        districtId: {
          type: ['string', 'null'],
          format: 'uuid',
        },
      },
      required: ['name'],
      additionalProperties: false,
    },
    CreateTradingPartnerCommandResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
      },
      required: ['id'],
      additionalProperties: false,
    },
    CurrencyInOffer: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        symbol: {
          type: ['string', 'null'],
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    CurrencyInOrder: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        symbol: {
          type: ['string', 'null'],
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    CurrencyListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        symbol: {
          type: ['string', 'null'],
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    DistrictInTradingPartner: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        city: {
          $ref: '#/definitions/CityInDistrict',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['city', 'id', 'name'],
      additionalProperties: false,
    },
    DistrictListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        city: {
          $ref: '#/definitions/CityInDistrict',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['city', 'id', 'name'],
      additionalProperties: false,
    },
    ForgotPasswordCommand: {
      type: 'object',
      properties: {
        email: {
          minLength: 1,
          type: 'string',
          format: 'email',
        },
      },
      required: ['email'],
      additionalProperties: false,
    },
    GetAccountQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        email: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        lastName: {
          minLength: 1,
          type: 'string',
        },
        createdDateTime: {
          type: 'string',
          format: 'date-time',
        },
        fullName: {
          minLength: 1,
          type: 'string',
        },
        emailConfirmDateTime: {
          type: ['string', 'null'],
          format: 'date-time',
        },
        isEmailConfirmed: {
          type: 'boolean',
        },
        accountLockedFromDateTime: {
          type: ['string', 'null'],
          format: 'date-time',
        },
        accountLockedToDateTime: {
          type: ['string', 'null'],
          format: 'date-time',
        },
        roles: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
      required: ['createdDateTime', 'email', 'fullName', 'id', 'isEmailConfirmed', 'lastName', 'name', 'roles'],
      additionalProperties: false,
    },
    GetCityQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    GetCurrencyQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        symbol: {
          type: ['string', 'null'],
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    GetDistrictQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        city: {
          $ref: '#/definitions/CityInDistrict',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['city', 'id', 'name'],
      additionalProperties: false,
    },
    GetOfferCategoryQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    GetOfferDetailListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        product: {
          $ref: '#/definitions/ProductInOfferDetails',
        },
        productCode: {
          type: ['string', 'null'],
        },
        productName: {
          type: ['string', 'null'],
        },
        quantity: {
          type: 'number',
          format: 'double',
        },
        productUnit: {
          $ref: '#/definitions/ProductUnitInOfferDetails',
        },
        price: {
          type: 'number',
          format: 'double',
        },
        subtotal: {
          type: 'number',
          format: 'double',
        },
        discountRate: {
          type: 'number',
          format: 'double',
        },
        discountAmount: {
          type: 'number',
          format: 'double',
        },
        vatRate: {
          type: 'number',
          format: 'double',
        },
        vatAmount: {
          type: 'number',
          format: 'double',
        },
        amount: {
          type: 'number',
          format: 'double',
        },
        description: {
          type: ['string', 'null'],
        },
      },
      additionalProperties: false,
    },
    GetOfferQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        category: {
          $ref: '#/definitions/OfferCategoryInOffer',
        },
        tradingPartner: {
          $ref: '#/definitions/TradingPartnerInOffer',
        },
        offerDateTime: {
          type: 'string',
          format: 'date-time',
        },
        currency: {
          $ref: '#/definitions/CurrencyInOffer',
        },
        subtotal: {
          type: 'number',
          format: 'double',
        },
        discountAmount: {
          type: 'number',
          format: 'double',
        },
        vatAmount: {
          type: 'number',
          format: 'double',
        },
        totalAmount: {
          type: 'number',
          format: 'double',
        },
        description: {
          type: ['string', 'null'],
        },
        details: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/GetOfferDetailListItem',
          },
        },
      },
      additionalProperties: false,
    },
    GetOrderCategoryQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    GetOrderDetailListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        product: {
          $ref: '#/definitions/ProductInOrderDetails',
        },
        productCode: {
          type: ['string', 'null'],
        },
        productName: {
          type: ['string', 'null'],
        },
        quantity: {
          type: 'number',
          format: 'double',
        },
        productUnit: {
          $ref: '#/definitions/ProductUnitInOrderDetails',
        },
        price: {
          type: 'number',
          format: 'double',
        },
        subtotal: {
          type: 'number',
          format: 'double',
        },
        discountRate: {
          type: 'number',
          format: 'double',
        },
        discountAmount: {
          type: 'number',
          format: 'double',
        },
        vatRate: {
          type: 'number',
          format: 'double',
        },
        vatAmount: {
          type: 'number',
          format: 'double',
        },
        amount: {
          type: 'number',
          format: 'double',
        },
        description: {
          type: ['string', 'null'],
        },
      },
      additionalProperties: false,
    },
    GetOrderQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        category: {
          $ref: '#/definitions/OrderCategoryInOrder',
        },
        tradingPartner: {
          $ref: '#/definitions/TradingPartnerInOrder',
        },
        orderDateTime: {
          type: 'string',
          format: 'date-time',
        },
        deliveryDateTime: {
          type: 'string',
          format: 'date-time',
        },
        currency: {
          $ref: '#/definitions/CurrencyInOrder',
        },
        subtotal: {
          type: 'number',
          format: 'double',
        },
        discountAmount: {
          type: 'number',
          format: 'double',
        },
        vatAmount: {
          type: 'number',
          format: 'double',
        },
        totalAmount: {
          type: 'number',
          format: 'double',
        },
        description: {
          type: ['string', 'null'],
        },
        isCompleted: {
          type: 'boolean',
        },
        details: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/GetOrderDetailListItem',
          },
        },
      },
      additionalProperties: false,
    },
    GetProductCategoryQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    GetProductQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        productUnit: {
          $ref: '#/definitions/ProductUnitInProduct',
        },
        productCategory: {
          $ref: '#/definitions/ProductCategoryInProduct',
        },
        buyingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        sellingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        wholesaleVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        retailVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
        sellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        sellingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
        calculatedWholesaleBuyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        calculatedRetailBuyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        calculatedWholesaleSellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        calculatedRetailSellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
      },
      required: ['buyingPriceCurrencyId', 'code', 'id', 'name', 'sellingPriceCurrencyId'],
      additionalProperties: false,
    },
    GetProductUnitQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    GetTradingPartnerQueryResult: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        taxOffice: {
          type: ['string', 'null'],
        },
        taxOrTcNumber: {
          type: ['string', 'null'],
        },
        webAddress: {
          type: ['string', 'null'],
        },
        emailAddress: {
          type: ['string', 'null'],
        },
        phone: {
          type: ['string', 'null'],
        },
        gsm: {
          type: ['string', 'null'],
        },
        postalCode: {
          type: ['string', 'null'],
        },
        addressTitle: {
          type: ['string', 'null'],
        },
        address: {
          type: ['string', 'null'],
        },
        district: {
          $ref: '#/definitions/DistrictInTradingPartner',
        },
        city: {
          $ref: '#/definitions/CityInTradingPartner',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    ListAccountsQuery: {
      type: 'object',
      properties: {
        recordNavigationRequest: {
          $ref: '#/definitions/ListAccountsQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListAccountsQueryResult: {
      type: 'object',
      properties: {
        accounts: {
          type: 'array',
          items: {
            $ref: '#/definitions/AccountListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['accounts', 'paginationData'],
      additionalProperties: false,
    },
    ListAccountsQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListAccountsQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListAccountsQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListAccountsQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListAccountsQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    ListCitiesQuery: {
      type: 'object',
      properties: {
        recordNavigationRequest: {
          $ref: '#/definitions/ListCitiesQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListCitiesQueryResult: {
      type: 'object',
      properties: {
        cities: {
          type: 'array',
          items: {
            $ref: '#/definitions/CityListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['cities', 'paginationData'],
      additionalProperties: false,
    },
    ListCitiesQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListCitiesQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListCitiesQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListCitiesQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListCitiesQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    ListCurrenciesQuery: {
      type: 'object',
      properties: {
        recordNavigationRequest: {
          $ref: '#/definitions/ListCurrenciesQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListCurrenciesQueryResult: {
      type: 'object',
      properties: {
        currencies: {
          type: 'array',
          items: {
            $ref: '#/definitions/CurrencyListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['currencies', 'paginationData'],
      additionalProperties: false,
    },
    ListCurrenciesQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListCurrenciesQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListCurrenciesQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListCurrenciesQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListCurrenciesQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    ListDistrictsQuery: {
      type: 'object',
      properties: {
        cityId: {
          type: ['string', 'null'],
          format: 'uuid',
        },
        recordNavigationRequest: {
          $ref: '#/definitions/ListDistrictsQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListDistrictsQueryResult: {
      type: 'object',
      properties: {
        districts: {
          type: 'array',
          items: {
            $ref: '#/definitions/DistrictListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['districts', 'paginationData'],
      additionalProperties: false,
    },
    ListDistrictsQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListDistrictsQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListDistrictsQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListDistrictsQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListDistrictsQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    ListOfferCategoriesQuery: {
      type: 'object',
      properties: {
        recordNavigationRequest: {
          $ref: '#/definitions/ListOfferCategoriesQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListOfferCategoriesQueryResult: {
      type: 'object',
      properties: {
        offerCategories: {
          type: 'array',
          items: {
            $ref: '#/definitions/OfferCategoryListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['offerCategories', 'paginationData'],
      additionalProperties: false,
    },
    ListOfferCategoriesQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListOfferCategoriesQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListOfferCategoriesQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListOfferCategoriesQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListOfferCategoriesQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    ListOfferQuery: {
      type: 'object',
      properties: {
        tradingPartnerName: {
          type: ['string', 'null'],
        },
        recordNavigationRequest: {
          $ref: '#/definitions/ListOfferQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListOfferQueryResult: {
      type: 'object',
      properties: {
        offers: {
          type: 'array',
          items: {
            $ref: '#/definitions/OfferListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['offers', 'paginationData'],
      additionalProperties: false,
    },
    ListOfferQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListOfferQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListOfferQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListOfferQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListOfferQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    ListOrderCategoriesQuery: {
      type: 'object',
      properties: {
        recordNavigationRequest: {
          $ref: '#/definitions/ListOrderCategoriesQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListOrderCategoriesQueryResult: {
      type: 'object',
      properties: {
        orderCategories: {
          type: 'array',
          items: {
            $ref: '#/definitions/OrderCategoryListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['orderCategories', 'paginationData'],
      additionalProperties: false,
    },
    ListOrderCategoriesQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListOrderCategoriesQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListOrderCategoriesQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListOrderCategoriesQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListOrderCategoriesQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    ListOrderQuery: {
      type: 'object',
      properties: {
        tradingPartnerName: {
          type: ['string', 'null'],
        },
        recordNavigationRequest: {
          $ref: '#/definitions/ListOrderQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListOrderQueryResult: {
      type: 'object',
      properties: {
        orders: {
          type: 'array',
          items: {
            $ref: '#/definitions/OrderListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['orders', 'paginationData'],
      additionalProperties: false,
    },
    ListOrderQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListOrderQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListOrderQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListOrderQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListOrderQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    ListProductCategoriesQuery: {
      type: 'object',
      properties: {
        recordNavigationRequest: {
          $ref: '#/definitions/ListProductCategoriesQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListProductCategoriesQueryResult: {
      type: 'object',
      properties: {
        productCategories: {
          type: 'array',
          items: {
            $ref: '#/definitions/ProductCategoryListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['paginationData', 'productCategories'],
      additionalProperties: false,
    },
    ListProductCategoriesQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListProductCategoriesQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListProductCategoriesQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListProductCategoriesQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListProductCategoriesQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    ListProductUnitsQuery: {
      type: 'object',
      properties: {
        recordNavigationRequest: {
          $ref: '#/definitions/ListProductUnitsQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListProductUnitsQueryResult: {
      type: 'object',
      properties: {
        productUnits: {
          type: 'array',
          items: {
            $ref: '#/definitions/ProductUnitListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['paginationData', 'productUnits'],
      additionalProperties: false,
    },
    ListProductUnitsQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListProductUnitsQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListProductUnitsQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListProductUnitsQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListProductUnitsQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    ListProductsQuery: {
      type: 'object',
      properties: {
        recordNavigationRequest: {
          $ref: '#/definitions/ListProductsQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListProductsQueryResult: {
      type: 'object',
      properties: {
        products: {
          type: 'array',
          items: {
            $ref: '#/definitions/ProductListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['paginationData', 'products'],
      additionalProperties: false,
    },
    ListProductsQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListProductsQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListProductsQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListProductsQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListProductsQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    ListTradingPartnersQuery: {
      type: 'object',
      properties: {
        recordNavigationRequest: {
          $ref: '#/definitions/ListTradingPartnersQuerySortFieldsEnumRecordNavigationRequest',
        },
      },
      additionalProperties: false,
    },
    ListTradingPartnersQueryResult: {
      type: 'object',
      properties: {
        tradingPartners: {
          type: 'array',
          items: {
            $ref: '#/definitions/TradingPartnerListItem',
          },
        },
        paginationData: {
          $ref: '#/definitions/PaginationData',
        },
      },
      required: ['paginationData', 'tradingPartners'],
      additionalProperties: false,
    },
    ListTradingPartnersQuerySortFieldsEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    ListTradingPartnersQuerySortFieldsEnumRecordNavigationRequest: {
      type: 'object',
      properties: {
        sorting: {
          type: ['array', 'null'],
          items: {
            $ref: '#/definitions/ListTradingPartnersQuerySortFieldsEnumSort',
          },
        },
        page: {
          type: 'integer',
          format: 'int32',
          default: 1,
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
          default: 50,
        },
      },
      additionalProperties: false,
    },
    ListTradingPartnersQuerySortFieldsEnumSort: {
      type: 'object',
      properties: {
        fieldName: {
          $ref: '#/definitions/ListTradingPartnersQuerySortFieldsEnum',
        },
        direction: {
          $ref: '#/definitions/SortDirectionEnum',
        },
      },
      required: ['direction', 'fieldName'],
      additionalProperties: false,
    },
    LoginCommand: {
      type: 'object',
      properties: {
        email: {
          minLength: 1,
          type: 'string',
        },
        password: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['email', 'password'],
      additionalProperties: false,
    },
    LoginCommandResult: {
      type: 'object',
      properties: {
        token: {
          minLength: 1,
          type: 'string',
        },
        refreshToken: {
          minLength: 1,
          type: 'string',
        },
        expiresIn: {
          type: 'integer',
          format: 'int32',
        },
      },
      required: ['expiresIn', 'refreshToken', 'token'],
      additionalProperties: false,
    },
    OfferCategoryInOffer: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    OfferCategoryListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    OfferListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        offerDateTime: {
          type: 'string',
          format: 'date-time',
        },
        createdAt: {
          type: 'string',
          format: 'date-time',
        },
        tradingPartnerName: {
          minLength: 1,
          type: 'string',
        },
        category: {
          $ref: '#/definitions/OfferCategoryInOffer',
        },
        totalAmount: {
          type: 'number',
          format: 'double',
        },
      },
      required: ['createdAt', 'id', 'offerDateTime', 'totalAmount', 'tradingPartnerName'],
      additionalProperties: false,
    },
    OrderCategoryInOrder: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    OrderCategoryListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    OrderListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        orderDateTime: {
          type: 'string',
          format: 'date-time',
        },
        deliveryDateTime: {
          type: 'string',
          format: 'date-time',
        },
        createdAt: {
          type: 'string',
          format: 'date-time',
        },
        tradingPartnerName: {
          minLength: 1,
          type: 'string',
        },
        category: {
          $ref: '#/definitions/OrderCategoryInOrder',
        },
        totalAmount: {
          type: 'number',
          format: 'double',
        },
        isCompleted: {
          type: 'boolean',
        },
      },
      required: ['createdAt', 'deliveryDateTime', 'id', 'isCompleted', 'orderDateTime', 'totalAmount', 'tradingPartnerName'],
      additionalProperties: false,
    },
    PaginationData: {
      type: 'object',
      properties: {
        currentPage: {
          type: 'integer',
          format: 'int32',
        },
        pageSize: {
          type: 'integer',
          format: 'int32',
        },
        totalPages: {
          type: 'integer',
          format: 'int32',
        },
        totalItems: {
          type: 'integer',
          format: 'int32',
        },
      },
      required: ['currentPage', 'pageSize', 'totalItems', 'totalPages'],
      additionalProperties: false,
    },
    ProductCategoryInProduct: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    ProductCategoryListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    ProductInOfferDetails: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        productUnit: {
          $ref: '#/definitions/ProductUnitInProduct',
        },
        productCategory: {
          $ref: '#/definitions/ProductCategoryInProduct',
        },
        buyingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        sellingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        wholesaleVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        retailVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
        sellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        sellingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
        calculatedWholesaleBuyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        calculatedRetailBuyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        calculatedWholesaleSellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        calculatedRetailSellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
      },
      required: ['buyingPriceCurrencyId', 'code', 'id', 'name', 'sellingPriceCurrencyId'],
      additionalProperties: false,
    },
    ProductInOrderDetails: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        productUnit: {
          $ref: '#/definitions/ProductUnitInProduct',
        },
        productCategory: {
          $ref: '#/definitions/ProductCategoryInProduct',
        },
        buyingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        sellingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        wholesaleVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        retailVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
        sellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        sellingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
        calculatedWholesaleBuyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        calculatedRetailBuyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        calculatedWholesaleSellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        calculatedRetailSellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
      },
      required: ['buyingPriceCurrencyId', 'code', 'id', 'name', 'sellingPriceCurrencyId'],
      additionalProperties: false,
    },
    ProductListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        unit: {
          $ref: '#/definitions/ProductUnitInProduct',
        },
        category: {
          $ref: '#/definitions/ProductCategoryInProduct',
        },
        buyingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        sellingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        wholesaleVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        retailVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
        sellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        sellingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
      },
      required: ['buyingPriceCurrencyId', 'code', 'id', 'name', 'sellingPriceCurrencyId'],
      additionalProperties: false,
    },
    ProductUnitInOfferDetails: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    ProductUnitInOrderDetails: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    ProductUnitInProduct: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    ProductUnitListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    RefreshCommand: {
      type: 'object',
      properties: {
        refreshToken: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['refreshToken'],
      additionalProperties: false,
    },
    RefreshCommandResult: {
      type: 'object',
      properties: {
        token: {
          minLength: 1,
          type: 'string',
        },
        refreshToken: {
          minLength: 1,
          type: 'string',
        },
        expiresIn: {
          type: 'integer',
          format: 'int32',
        },
      },
      required: ['expiresIn', 'refreshToken', 'token'],
      additionalProperties: false,
    },
    SortDirectionEnum: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    TradingPartnerInOffer: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        taxOffice: {
          type: ['string', 'null'],
        },
        taxOrTcNumber: {
          type: ['string', 'null'],
        },
        webAddress: {
          type: ['string', 'null'],
        },
        emailAddress: {
          type: ['string', 'null'],
        },
        phone: {
          type: ['string', 'null'],
        },
        gsm: {
          type: ['string', 'null'],
        },
        postalCode: {
          type: ['string', 'null'],
        },
        addressTitle: {
          type: ['string', 'null'],
        },
        address: {
          type: ['string', 'null'],
        },
        district: {
          $ref: '#/definitions/DistrictInTradingPartner',
        },
        city: {
          $ref: '#/definitions/CityInTradingPartner',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    TradingPartnerInOrder: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        taxOffice: {
          type: ['string', 'null'],
        },
        taxOrTcNumber: {
          type: ['string', 'null'],
        },
        webAddress: {
          type: ['string', 'null'],
        },
        emailAddress: {
          type: ['string', 'null'],
        },
        phone: {
          type: ['string', 'null'],
        },
        gsm: {
          type: ['string', 'null'],
        },
        postalCode: {
          type: ['string', 'null'],
        },
        addressTitle: {
          type: ['string', 'null'],
        },
        address: {
          type: ['string', 'null'],
        },
        district: {
          $ref: '#/definitions/DistrictInTradingPartner',
        },
        city: {
          $ref: '#/definitions/CityInTradingPartner',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    TradingPartnerListItem: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          minLength: 1,
          type: 'string',
        },
        taxOffice: {
          type: ['string', 'null'],
        },
        taxOrTcNumber: {
          type: ['string', 'null'],
        },
        webAddress: {
          type: ['string', 'null'],
        },
        emailAddress: {
          type: ['string', 'null'],
        },
        phone: {
          type: ['string', 'null'],
        },
        gsm: {
          type: ['string', 'null'],
        },
        postalCode: {
          type: ['string', 'null'],
        },
        addressTitle: {
          type: ['string', 'null'],
        },
        address: {
          type: ['string', 'null'],
        },
        city: {
          $ref: '#/definitions/CityInTradingPartner',
        },
        district: {
          $ref: '#/definitions/DistrictInTradingPartner',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    Unit: {
      type: 'object',
      properties: {},
      additionalProperties: false,
    },
    UpdateAccountCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        email: {
          maxLength: 256,
          minLength: 1,
          type: 'string',
          format: 'email',
        },
        password: {
          type: ['string', 'null'],
        },
        name: {
          maxLength: 70,
          minLength: 2,
          type: 'string',
        },
        lastName: {
          maxLength: 70,
          minLength: 2,
          type: 'string',
        },
        isEmailConfirmed: {
          type: 'boolean',
        },
        roles: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
      required: ['email', 'id', 'isEmailConfirmed', 'lastName', 'name', 'roles'],
      additionalProperties: false,
    },
    UpdateCityCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          maxLength: 50,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    UpdateCurrencyCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          maxLength: 3,
          minLength: 3,
          type: 'string',
        },
        name: {
          maxLength: 60,
          minLength: 1,
          type: 'string',
        },
        symbol: {
          maxLength: 1,
          type: ['string', 'null'],
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    UpdateDistrictCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        cityId: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          maxLength: 60,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['cityId', 'id', 'name'],
      additionalProperties: false,
    },
    UpdateOfferCategoryCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          maxLength: 50,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    UpdateOfferCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        categoryId: {
          type: ['string', 'null'],
          format: 'uuid',
        },
        tradingPartnerId: {
          type: 'string',
          format: 'uuid',
        },
        offerDateTime: {
          type: 'string',
          format: 'date-time',
        },
        currencyId: {
          type: 'string',
          format: 'uuid',
        },
        description: {
          maxLength: 250,
          type: ['string', 'null'],
        },
        details: {
          type: 'array',
          items: {
            $ref: '#/definitions/UpdateOfferDetailListItem',
          },
        },
      },
      required: ['currencyId', 'details', 'id', 'offerDateTime', 'tradingPartnerId'],
      additionalProperties: false,
    },
    UpdateOfferDetailListItem: {
      type: 'object',
      properties: {
        id: {
          type: ['string', 'null'],
          format: 'uuid',
        },
        productId: {
          type: 'string',
          format: 'uuid',
        },
        productCode: {
          maxLength: 25,
          minLength: 1,
          type: 'string',
        },
        productName: {
          maxLength: 150,
          minLength: 1,
          type: 'string',
        },
        quantity: {
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        productUnitId: {
          type: 'string',
          format: 'uuid',
        },
        price: {
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        discountRate: {
          maximum: 100,
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        vatRate: {
          maximum: 100,
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        description: {
          maxLength: 250,
          type: ['string', 'null'],
        },
      },
      required: ['discountRate', 'price', 'productCode', 'productId', 'productName', 'productUnitId', 'quantity', 'vatRate'],
      additionalProperties: false,
    },
    UpdateOrderCategoryCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          maxLength: 50,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    UpdateOrderCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        categoryId: {
          type: ['string', 'null'],
          format: 'uuid',
        },
        tradingPartnerId: {
          type: 'string',
          format: 'uuid',
        },
        orderDateTime: {
          type: 'string',
          format: 'date-time',
        },
        deliveryDateTime: {
          type: 'string',
          format: 'date-time',
        },
        currencyId: {
          type: 'string',
          format: 'uuid',
        },
        description: {
          maxLength: 250,
          type: ['string', 'null'],
        },
        details: {
          type: 'array',
          items: {
            $ref: '#/definitions/UpdateOrderDetailListItem',
          },
        },
      },
      required: ['currencyId', 'deliveryDateTime', 'details', 'id', 'orderDateTime', 'tradingPartnerId'],
      additionalProperties: false,
    },
    UpdateOrderDetailListItem: {
      type: 'object',
      properties: {
        id: {
          type: ['string', 'null'],
          format: 'uuid',
        },
        productId: {
          type: 'string',
          format: 'uuid',
        },
        productCode: {
          maxLength: 25,
          minLength: 1,
          type: 'string',
        },
        productName: {
          maxLength: 150,
          minLength: 1,
          type: 'string',
        },
        quantity: {
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        productUnitId: {
          type: 'string',
          format: 'uuid',
        },
        price: {
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        discountRate: {
          maximum: 100,
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        vatRate: {
          maximum: 100,
          minimum: 0,
          type: 'number',
          format: 'double',
        },
        description: {
          maxLength: 250,
          type: ['string', 'null'],
        },
      },
      required: ['discountRate', 'price', 'productCode', 'productId', 'productName', 'productUnitId', 'quantity', 'vatRate'],
      additionalProperties: false,
    },
    UpdateProductCategoryCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          maxLength: 60,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    UpdateProductCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          maxLength: 25,
          minLength: 1,
          type: 'string',
        },
        name: {
          maxLength: 150,
          minLength: 1,
          type: 'string',
        },
        unitId: {
          type: 'string',
          format: 'uuid',
        },
        categoryId: {
          type: 'string',
          format: 'uuid',
        },
        buyingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        sellingPriceCurrencyId: {
          type: 'string',
          format: 'uuid',
        },
        wholesaleVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        retailVatRate: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        buyingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
        sellingPrice: {
          type: ['number', 'null'],
          format: 'double',
        },
        sellingPriceExcludingVat: {
          type: ['boolean', 'null'],
        },
      },
      required: ['buyingPriceCurrencyId', 'categoryId', 'code', 'id', 'name', 'sellingPriceCurrencyId', 'unitId'],
      additionalProperties: false,
    },
    UpdateProductUnitCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        code: {
          minLength: 1,
          type: 'string',
        },
        name: {
          maxLength: 50,
          minLength: 1,
          type: 'string',
        },
      },
      required: ['code', 'id', 'name'],
      additionalProperties: false,
    },
    UpdateTradingPartnerCommand: {
      type: 'object',
      properties: {
        id: {
          type: 'string',
          format: 'uuid',
        },
        name: {
          maxLength: 200,
          minLength: 1,
          type: 'string',
        },
        taxOffice: {
          maxLength: 50,
          type: ['string', 'null'],
        },
        taxOrTcNumber: {
          maxLength: 11,
          minLength: 10,
          pattern: '^([1-9]{1}[0-9]{9}[02468]{1}|[0-9]{10})$',
          type: ['string', 'null'],
        },
        webAddress: {
          maxLength: 255,
          type: ['string', 'null'],
        },
        emailAddress: {
          maxLength: 150,
          type: ['string', 'null'],
          format: 'email',
        },
        phone: {
          maxLength: 10,
          minLength: 7,
          pattern: '^([1-9]{1}[0-9]{9}|[1-9]{1}[0-9]{6})$',
          type: ['string', 'null'],
        },
        gsm: {
          maxLength: 10,
          minLength: 10,
          pattern: '^([1-9]{1}[0-9]{9})$',
          type: ['string', 'null'],
        },
        postalCode: {
          maxLength: 10,
          type: ['string', 'null'],
        },
        addressTitle: {
          maxLength: 50,
          type: ['string', 'null'],
        },
        address: {
          maxLength: 255,
          type: ['string', 'null'],
        },
        cityId: {
          type: ['string', 'null'],
          format: 'uuid',
        },
        districtId: {
          type: ['string', 'null'],
          format: 'uuid',
        },
      },
      required: ['id', 'name'],
      additionalProperties: false,
    },
    VerifyPasswordResetCommand: {
      type: 'object',
      properties: {
        email: {
          minLength: 1,
          type: 'string',
          format: 'email',
        },
        resetCode: {
          minLength: 1,
          type: 'string',
        },
        newPassword: {
          minLength: 1,
          type: 'string',
        },
      },
      required: ['email', 'newPassword', 'resetCode'],
      additionalProperties: false,
    },
  },
}
