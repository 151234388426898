import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Box, Button, Container, FormControl, FormErrorMessage, FormLabel, Heading, Input, Stack, useColorModeValue, useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import { MyAccountApi } from '../../utils/api-service.util'
import VerifyPasswordForgottenPage from '../../components/verify-password-forgotten/verify-password-forgotten.page'

const ForgetPasswordPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'forgetPassword' })
  const toast = useToast()
  const [mail, setMail] = useState('')
  const [showSetNewPasswordSection, setShowSetNewPasswordSection] = useState(false)

  const submitMutation = useMutation({
    mutationKey: [FormSchemaTypes.ForgotPasswordCommand],
    mutationFn: MyAccountApi.apiMyAccountForgotPasswordPost,
    onSuccess: () => {
      toast({
        title: t('sendSuccessTitle'),
        description: t('sendSuccessDescription'),
        status: 'success',
      })
      setShowSetNewPasswordSection(true)
    },
    onError: () => {
      toast({
        title: t('sendFailedTitle'),
        description: t('sendFailedDescription'),
        status: 'error',
      })
    },
  })

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('emailInvalid')).required(t('emailRequired')),
    }),
    initialErrors: {
      email: t('emailRequired'),
    },
    onSubmit: values => {
      submitMutation.mutate(values)
      setMail(values.email)
    },
  })

  const boxBg = useColorModeValue('gray.50', 'gray.700')

  if (showSetNewPasswordSection) {
    return <VerifyPasswordForgottenPage email={mail} />
  }

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '4', md: '8' }}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing="8">
          <Heading textAlign="center">{t('title')}</Heading>
          <Box bg={boxBg} boxShadow="md" p="8" borderRadius="xl">
            <Stack spacing="6">
              <FormControl isInvalid={formik.touched.email && !!formik.errors.email}>
                <FormLabel htmlFor="email">{t('emailLabel')}</FormLabel>
                <Input id="email" type="email" {...formik.getFieldProps('email')} />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
              <Button isLoading={submitMutation.isPending} loadingText={t('sending')} colorScheme="blue" type="submit" isDisabled={!formik.isValid}>
                {t('sendButton')}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </form>
    </Container>
  )
}

export default ForgetPasswordPage
