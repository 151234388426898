import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DataTable from '../../components/data-table/data-table.component'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { OfferApi } from '../../utils/api-service.util'
import { useNavigate } from 'react-router-dom'
import RoutesEnum from '../../enums/routes.enum'
import { Box, Button, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, useToast } from '@chakra-ui/react'
import { ListOfferQuery, ListOfferQuerySortFieldsEnum, SortDirectionEnum } from '@vennyx-org/erp-api-client'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import moment from 'moment-timezone'
import { Column, Row, SortDirection } from '../../components/data-table/data-table.component.types'

const OffersPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'offers' })
  const toast = useToast()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedRow, setSelectedRow] = useState<Row | null>(null)
  const [selectedDeliveryDateTime, setSelectedDeliveryDateTime] = useState<Date | null>(null)
  const [query, setQuery] = useState<ListOfferQuery>({
    recordNavigationRequest: {
      page: 1,
      pageSize: 50,
      sorting: [{ direction: SortDirectionEnum.ASCENDING, fieldName: ListOfferQuerySortFieldsEnum.CREATED_AT }],
    },
  })

  const { data, error, isFetching, refetch } = useQuery({
    queryKey: [FormSchemaTypes.ListOfferQuery, query],
    queryFn: () => OfferApi.apiOffersPut(query),
    select: data => {
      let computedData = data
      if (data.data.offers) {
        computedData.data.offers = data.data.offers.map(offer => {
          return {
            ...offer,
            offerDateTime: moment(offer.offerDateTime).format('YYYY-MM-DD HH:mm'),
            createdAt: moment(offer.createdAt).format('YYYY-MM-DD HH:mm'),
          }
        })
      }

      return computedData
    },
  })

  const removeOffersMutation = useMutation({
    mutationFn: OfferApi.apiOfferDelete,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FormSchemaTypes.ListOfferQuery] })
    },
  })

  const convertToOrderMutation = useMutation({
    mutationKey: [FormSchemaTypes.ConvertToOrderCommand],
    mutationFn: OfferApi.apiOfferConvertToOrderPost,
    onSuccess: () => {
      navigate(RoutesEnum.ORDERS)
      toast({
        title: t('convertSuccess'),
        status: 'success',
      })
    },
    onError: () => {
      toast({
        title: t('convertFailed'),
        status: 'error',
      })
    },
  })

  const handlePageChange = (page: number) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        ...query.recordNavigationRequest,
        page,
      },
    })
  }

  const handlePageSizeChange = (pageSize: number) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        page: 1,
        pageSize,
      },
    })
  }

  const columns: Column[] = useMemo(
    () => [
      {
        key: 'category.name',
        title: t('categoryId'),
      },
      {
        key: 'tradingPartnerName',
        title: t('tradingPartnerId'),
      },
      {
        key: 'offerDateTime',
        title: t('offerDateTime'),
        sortable: true,
      },
      {
        key: 'createdAt',
        title: t('createdAt'),
        sortable: true,
      },
      {
        key: 'totalAmount',
        title: t('totalAmount'),
        sortable: true,
      },
    ],
    [t]
  )

  const handleSort = (column: string, direction: SortDirection) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        ...query.recordNavigationRequest,
        sorting: [
          {
            fieldName: column as ListOfferQuerySortFieldsEnum,
            direction: direction === SortDirection.ASCENDING ? SortDirectionEnum.ASCENDING : SortDirectionEnum.DESCENDING,
          },
        ],
      },
    })
  }

  const openDeliveryDateTimeModal = (props: Row) => {
    setSelectedRow(props)
    onOpen()
  }

  const handleConvertToOrderClick = () => {
    if (selectedRow) {
      convertToOrderMutation.mutate({
        id: selectedRow.id,
        deliveryDateTime: selectedDeliveryDateTime?.toISOString(),
      })
    }
  }

  return (
    <Box mt={4}>
      <Heading mb={4}>{t('title')}</Heading>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('selectDeliveryTimeHeader')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input placeholder={t('selectTime')} size="md" type="datetime-local" onChange={e => setSelectedDeliveryDateTime(new Date(e.target.value))} />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button onClick={handleConvertToOrderClick} isLoading={convertToOrderMutation.isPending}>
              {t('convert')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DataTable
        data={data?.data.offers || []}
        columns={columns}
        onRefresh={refetch}
        onRowDelete={row => removeOffersMutation.mutate(row.id)}
        onRowEditClick={row => navigate(RoutesEnum.OFFERS_EDIT.replace(':id', row.id))}
        onRowConvertToOrderClick={openDeliveryDateTimeModal}
        onCreateClick={() => navigate(RoutesEnum.OFFERS_CREATE)}
        error={error?.message}
        isLoading={isFetching}
        currentPage={query.recordNavigationRequest?.page}
        pageSize={query.recordNavigationRequest?.pageSize}
        totalPageCount={data?.data.paginationData?.totalPages}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        isRowDeleting={removeOffersMutation.isPending}
        isOfferConverting={convertToOrderMutation.isPending}
        translationKeyPrefix="offers"
        defaultSort={{ createdAt: SortDirection.ASCENDING }}
        onSort={handleSort}
      />
    </Box>
  )
}

export default OffersPage
