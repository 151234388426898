import { useTranslation } from 'react-i18next'
import { Box, Center, Heading, Spinner, Text, useToast } from '@chakra-ui/react'
import { Form, UiSchema } from '@rjsf/chakra-ui'
import schema from '../../utils/form-schema.util'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import validator from '@rjsf/validator-ajv8'
import React, { useCallback, useEffect } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { OrderApi } from '../../utils/api-service.util'
import { useParams } from 'react-router-dom'
import TradingPartnerSelectWidget from '../../components/trading-partner-select-widget/trading-partner-select-widget.component'
import CurrencySelectWidget from '../../components/currency-select-widget/currency-select.widget.component'
import ProductSelectWidget from '../../components/product-select-widget/product-select-widget.component'
import ProductUnitSelectWidget from '../../components/product-unit-select-widget/product-unit-select-widget.component'
import fixupFormStrings, { fixupFormErrorStrings } from '../../utils/fixup-form-strings.util'
import { ProductListItem } from '@vennyx-org/erp-api-client'
import { RJSFSchema } from '@rjsf/utils'
import OrderCategorySelectWidget from '../../components/order-category-select-widget/order-category-select-widget.component'

const OrderEditPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'orders' })
  const { id } = useParams()
  const toast = useToast()
  const [formData, setFormData] = React.useState<RJSFSchema>({ schema: {} })
  const [selectedProducts, setSelectedProducts] = React.useState<ProductListItem[]>([])

  const orderQuery = useQuery({
    queryKey: [FormSchemaTypes.GetOrderQueryResult, id],
    queryFn: () => OrderApi.apiOrderGet(id ?? ''),
    enabled: !!id,
  })

  const updateMutation = useMutation({
    mutationFn: OrderApi.apiOrderPatch,
    onSuccess: () => {
      toast({
        title: t('updateSuccess'),
        status: 'success',
      })
    },
    onError: () => {
      toast({
        title: t('updateFailed'),
        status: 'error',
      })
    },
  })

  useEffect(() => {
    if (orderQuery.data?.data) {
      const { data } = orderQuery.data
      setFormData({
        schema: {
          id: data.id,
          categoryId: data.category?.id,
          tradingPartnerId: data.tradingPartner?.id,
          orderDateTime: data.orderDateTime,
          deliveryDateTime: data.deliveryDateTime,
          currencyId: data.currency?.id,
          description: data.description,
          details: data.details?.map(detail => ({
            id: detail.id,
            productId: detail.product?.id,
            productCode: detail.productCode,
            productName: detail.productName,
            quantity: detail.quantity,
            productUnitId: detail.productUnit?.id,
            price: detail.price,
            discountRate: detail.discountRate,
            vatRate: detail.vatRate,
            description: detail.description,
          })),
        },
      })
    }
  }, [orderQuery.data])

  useEffect(() => {
    const formDataDetails = formData.schema.details || []
    if (formDataDetails.length > 0) {
      const unchangedDetails = formDataDetails.filter((detail: any) => {
        const product = selectedProducts.find(p => p.id === detail.productId)
        return product && product.code !== detail.productCode && product.name !== detail.productName
      })

      if (unchangedDetails.length > 0) {
        setFormData(prevFormData => {
          const updatedDetails = prevFormData.schema.details.map((detail: any) => {
            const product = selectedProducts.find(p => p.id === detail.productId)
            if (product && product.code !== detail.productCode && product.name !== detail.productName) {
              return {
                ...detail,
                productCode: product.code,
                productName: product.name,
              }
            }
            return detail
          })
          return { ...prevFormData, schema: { ...prevFormData.schema, details: updatedDetails } }
        })
      }
    }
  }, [selectedProducts, formData])

  const handleProductSelect = useCallback((product: ProductListItem) => {
    setSelectedProducts(prevProducts => {
      if (prevProducts.some(p => p.id === product.id)) {
        return prevProducts
      }
      return [...prevProducts, product]
    })
  }, [])

  const uiSchema: UiSchema = {
    'ui:submitButtonOptions': {
      submitText: t('update'),
      props: {
        isDisabled: orderQuery.isFetching || updateMutation.isPending,
      },
    },
    schema: {
      id: {
        'ui:title': t('id'),
        'ui:readonly': true,
      },
      categoryId: {
        'ui:title': t('categoryId'),
        'ui:widget': OrderCategorySelectWidget,
      },
      tradingPartnerId: {
        'ui:title': t('tradingPartnerId'),
        'ui:widget': TradingPartnerSelectWidget,
      },
      orderDateTime: {
        'ui:title': t('orderDateTime'),
      },
      deliveryDateTime: {
        'ui:title': t('deliveryDateTime'),
      },
      currencyId: {
        'ui:title': t('currencyId'),
        'ui:widget': CurrencySelectWidget,
      },
      description: {
        'ui:title': t('description'),
      },
      details: {
        'ui:label': false,
        items: {
          'ui:title': t('productDetails'),
          id: {
            'ui:title': t('id'),
            'ui:readonly': true,
          },
          productId: {
            'ui:title': t('productId'),
            'ui:widget': ProductSelectWidget,
            'ui:options': {
              onProductSelect: handleProductSelect,
            },
          },
          productCode: { 'ui:title': t('productCode'), 'ui:readonly': true },
          productName: { 'ui:title': t('productName'), 'ui:readonly': true },
          quantity: { 'ui:title': t('quantity') },
          productUnitId: { 'ui:title': t('productUnitId'), 'ui:widget': ProductUnitSelectWidget },
          price: { 'ui:title': t('price') },
          discountRate: { 'ui:title': t('discountRate') },
          vatRate: { 'ui:title': t('vatRate') },
          description: { 'ui:title': t('description') },
        },
      },
    },
  }

  if (orderQuery.isError) {
    return (
      <Center height="calc(100dvh - 10rem)">
        <Text color="red.500">{t('fetchFailed')}</Text>
      </Center>
    )
  }

  if (orderQuery.isFetching || !formData) {
    return (
      <Center height="calc(100dvh - 10rem)">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box width="100%" display="flex" mt={15} flexDirection="column" alignItems="center">
      <Heading mb={4}>{t('edit')}</Heading>
      <Box width="full" maxWidth="lg">
        <Form
          formData={formData}
          schema={schema('', FormSchemaTypes.UpdateOrderCommand)}
          onChange={data => setFormData(data.formData)}
          onSubmit={data => updateMutation.mutate(data.formData.schema)}
          disabled={updateMutation.isPending}
          validator={validator}
          uiSchema={uiSchema}
          showErrorList={false}
          noHtml5Validate
          focusOnFirstError
          translateString={fixupFormStrings}
          transformErrors={fixupFormErrorStrings}
        />
      </Box>
    </Box>
  )
}

export default OrderEditPage
