import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DataTable from '../../components/data-table/data-table.component'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { DistrictApi } from '../../utils/api-service.util'
import { useNavigate } from 'react-router-dom'
import RoutesEnum from '../../enums/routes.enum'
import { Box, Heading } from '@chakra-ui/react'
import { ListDistrictsQuery, ListDistrictsQuerySortFieldsEnum, SortDirectionEnum } from '@vennyx-org/erp-api-client'
import FormSchemaTypes from '../../enums/form-schema-types.enum'
import { SortDirection } from '../../components/data-table/data-table.component.types'

const CitiesPage = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'districts' })
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [query, setQuery] = useState<ListDistrictsQuery>({
    recordNavigationRequest: {
      page: 1,
      pageSize: 50,
      sorting: [{ direction: SortDirectionEnum.ASCENDING, fieldName: ListDistrictsQuerySortFieldsEnum.NAME }],
    },
  })

  const { data, error, isFetching, refetch } = useQuery({
    queryKey: [FormSchemaTypes.ListDistrictsQuery, query],
    queryFn: () => DistrictApi.apiDistrictsPut(query),
  })

  const removeCityMutation = useMutation({
    mutationFn: DistrictApi.apiDistrictDelete,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [FormSchemaTypes.ListDistrictsQuery] })
    },
  })

  const handlePageChange = (page: number) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        ...query.recordNavigationRequest,
        page,
      },
    })
  }

  const handlePageSizeChange = (pageSize: number) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        page: 1,
        pageSize,
      },
    })
  }

  const columns = useMemo(
    () => [
      {
        key: 'name',
        title: t('name'),
        sortable: true,
      },
      {
        key: 'city.name',
        title: t('cityName'),
      },
    ],
    [t]
  )

  const handleSort = (column: string, direction: SortDirection) => {
    setQuery({
      ...query,
      recordNavigationRequest: {
        ...query.recordNavigationRequest,
        sorting: [
          {
            fieldName: column as ListDistrictsQuerySortFieldsEnum,
            direction: direction === SortDirection.ASCENDING ? SortDirectionEnum.ASCENDING : SortDirectionEnum.DESCENDING,
          },
        ],
      },
    })
  }

  return (
    <Box mt={4}>
      <Heading mb={4}>{t('title')}</Heading>
      <DataTable
        data={data?.data.districts || []}
        columns={columns}
        onRefresh={refetch}
        onRowDelete={row => removeCityMutation.mutate(row.id)}
        onRowEditClick={row => navigate(RoutesEnum.DISTRICTS_EDIT.replace(':id', row.id))}
        onCreateClick={() => navigate(RoutesEnum.DISTRICTS_CREATE)}
        error={error?.message}
        isLoading={isFetching}
        currentPage={query.recordNavigationRequest?.page}
        pageSize={query.recordNavigationRequest?.pageSize}
        totalPageCount={data?.data.paginationData?.totalPages}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        isRowDeleting={removeCityMutation.isPending}
        translationKeyPrefix="districts"
        defaultSort={{ name: SortDirection.ASCENDING }}
        onSort={handleSort}
      />
    </Box>
  )
}

export default CitiesPage
