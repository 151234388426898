export interface Column {
  key: string
  title: string
  sortable?: boolean
  hidden?: boolean
}

export interface Row {
  [key: string]: any
}

export enum SortDirection {
  ASCENDING = 'Ascending',
  DESCENDING = 'Descending',
  NONE = '',
}

export interface SortState {
  [key: string]: SortDirection
}

export interface TableLayoutProps {
  isLoading?: boolean
  columns: Column[]
  data: object[]
  enableMultipleSort?: boolean
  defaultSort?: SortState
  onRowDelete?: (row: object) => void
  onRowDetailClick?: () => void
  onRowEditClick?: (row: Row) => void
  onRowConvertToOrderClick?: (row: Row) => void
  onRowCompleteOrderClick?: (row: Row) => void
  onRowClick: (row: Row) => void
  onSort?: (column: string, direction: SortDirection) => void
  isRowDeleting?: boolean
  isOfferConverting?: boolean
  isOrderCompleting?: boolean
}

export interface DataRowProps {
  row: Row
  columns: Column[]
  onRowDelete?: (row: Row) => void
  onRowDetailClick?: (row: Row) => void
  onRowEditClick?: (row: Row) => void
  onRowConvertToOrderClick?: (row: Row) => void
  onRowCompleteOrderClick?: (row: Row) => void
  onRowClick: (row: Row) => void
  isRowDeleting?: boolean
  isOfferConverting?: boolean
  isOrderCompleting?: boolean
}

export interface NoDataRowProps {
  colSpan: number
}

export interface ActionButtonsProps {
  isRefreshing?: boolean
  onRefresh?: () => void
  onCreateClick?: () => void
  onPageSizeChange?: (perPage: number) => void
  pageSize?: number
  onExportExcel?: () => void
  onExportPdf?: () => void
}

export interface DetailsModalProps {
  isOpen: boolean
  onClose: () => void
  selectedRow: Row | null
  translationKeyPrefix?: string
}

export interface ErrorMessageProps {
  error?: string
  isShown?: boolean
}

export interface PaginationControlProps {
  currentPage: number
  totalPageCount: number
  onPageChange?: (page: number) => void
  pageSize: number
  isPaginationShown?: boolean
  isPaginationDisabled?: boolean
}

export interface SkeletonRowsProps {
  rowCount: number
  columnCount: number
}

export interface DataTableProps {
  data: object[]
  columns: Column[]
  isLoading?: boolean
  pageSize?: number
  currentPage?: number
  totalPageCount?: number
  error?: string
  onPageChange?: (page: number) => void
  onPageSizeChange?: (perPage: number) => void
  onRefresh?: () => void
  onRowDelete?: (row: Row) => void
  onRowEditClick?: (row: Row) => void
  onRowConvertToOrderClick?: (row: Row) => void
  onRowCompleteOrderClick?: (row: Row) => void
  onCreateClick?: () => void
  onSort?: (column: string, direction: SortDirection) => void
  defaultSort?: SortState
  isRowDeleting?: boolean
  isOfferConverting?: boolean
  isOrderCompleting?: boolean
  translationKeyPrefix?: string
}
