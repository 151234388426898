import React, { FC } from 'react'
import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, useColorModeValue } from '@chakra-ui/react'
import { DetailsModalProps } from './data-table.component.types'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

const RenderValue: FC<{
  value: any
  title?: string
  t: TFunction<'translation', string>
  keyTranslation: TFunction<'translation', string>
}> = ({ value, title, t, keyTranslation }) => {
  const boxBg = useColorModeValue('gray.50', 'gray.600')
  const boxHoverBg = useColorModeValue('gray.100', 'gray.500')
  const subBoxHoverBg = useColorModeValue('gray.50', 'gray.400')

  if (typeof value === 'object') {
    return (
      <Box mb={1} boxShadow="md" py={2} px={4} borderRadius="md" bg={boxBg} _hover={{ bg: boxHoverBg }}>
        {title && <Text fontWeight={600}>{keyTranslation(title)}</Text>}
        {!value && <Text color="gray.300">{t('empty')}</Text>}
        {Array.isArray(value)
          ? value.map((item, index) => <RenderValue key={index} value={item} t={t} keyTranslation={keyTranslation} />)
          : Object.keys(value || {}).map(key => <RenderValue key={key} title={key} value={value?.[key]} t={t} keyTranslation={keyTranslation} />)}
      </Box>
    )
  }

  let computedValue = value
  if (typeof value === 'boolean') computedValue = value ? t('yes') : t('no')
  return (
    <Box mb={1} boxShadow="md" py={2} px={4} borderRadius="md" bg={boxBg} _hover={{ bg: subBoxHoverBg }}>
      {title && <Text fontWeight={600}>{keyTranslation(title)}</Text>}
      <Text color={computedValue ? 'inherit' : 'gray.500'}>{computedValue || t('empty')}</Text>
    </Box>
  )
}

const DetailsModal: FC<DetailsModalProps> = ({ isOpen, onClose, selectedRow, translationKeyPrefix }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'dataTable' })
  const { t: keyTranslation } = useTranslation(undefined, { keyPrefix: translationKeyPrefix })

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('details')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {selectedRow && (
            <Box>
              {Object.keys(selectedRow).map(key => (
                <RenderValue key={key} title={key} value={selectedRow[key]} t={t} keyTranslation={keyTranslation} />
              ))}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DetailsModal
